import { DNI, BIRTHDAY } from "../../../validations/medicalRecordAr";
import FormField from "../../FormFiel/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

function PersonalInformationAr({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        id="adult"
        label="¿Eres mayor de 21 años?"
        name="adulto"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      <FormField
        label="Género"
        id="gender"
        required={true}
        name="genero"
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar género" },
          { value: "F", label: "Femenino" },
          { value: "M", label: "Masculino" },
          { value: "X", label: "No Binario" },
        ]}
      />

      <FormField
        id="idType"
        name="tipo de documento"
        label="Tipo de documento"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Tipo de documento" },
          { value: "dni", label: "DNI" },
          { value: "le", label: "LE" },
          { value: "lc", label: "LC" },
          { value: "ci", label: "CI" },
        ]}
      />

      <FormField
        id="idNumber"
        name="Número de documento"
        label="Número de documento"
        required={true}
        type="number"
        maxLength={8}
        minLength={2}
        placeholder="Número de documento"
        errors={errors}
        register={register}
        //validation={DNI}
      />

      <FormField
        label="Fecha de nacimiento"
        id="birthDate"
        required={true}
        name="fecha de nacimiento"
        type="date"
        errors={errors}
        register={register}
        //validation={BIRTHDAY}
      />
    </>
  );
}

export default PersonalInformationAr;
