import { MEDICAMENTOS } from "../../../validations/medicalRecordAr";
import FormField from "../../FormFiel/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
  watch?: any;
};

function MedicalInformationAr({ register, errors, watch }: PropsForm) {
  const question2 = watch("question2");

  return (
    <>
      <div>
        <p>¿Tiene arritmias o angor en actividad?</p>
        <div className="flex justify-around">
          <FormField
            label="SI"
            id="question1"
            required={true}
            name="pregunta1"
            value="si"
            type="radio"
            errors={errors}
            register={register}
          />
          <FormField
            label="NO"
            id="question1"
            required={true}
            name="pregunta1"
            value="no"
            type="radio"
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className="mt-4">
        <p>
          ¿Tiene antecedentes de algún padecimiento en salud mental? ¿Se
          encuentra actualmente en tratamiento?
        </p>
        <div className="flex justify-around">
          <FormField
            label="SI"
            id="question2"
            required={true}
            name="pregunta2"
            value="si"
            type="radio"
            errors={errors}
            register={register}
          />
          <FormField
            label="NO"
            id="question2"
            required={true}
            name="pregunta2"
            value="no"
            type="radio"
            errors={errors}
            register={register}
          />
        </div>

        {question2 === "si" && (
          <FormField
            label="Especificar"
            id="specification"
            required={true}
            name="especificacion"
            type="text"
            placeholder="Especificar"
            errors={errors}
            register={register}
            //validation={MEDICAMENTOS}
          />
        )}
      </div>

      <div className="mt-4">
        <p>¿Es alérgico/a a algún componente de la planta de cannabis?</p>
        <div className="flex justify-around">
          <FormField
            label="SI"
            id="question3"
            required={true}
            name="pregunta3"
            value="si"
            type="radio"
            errors={errors}
            register={register}
          />
          <FormField
            label="NO"
            id="question3"
            required={true}
            name="pregunta3"
            value="no"
            type="radio"
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className="mt-4">
        <p>¿Se encuentra en embarazo en curso o lactancia?</p>
        <div className="flex justify-around">
          <FormField
            label="SI"
            id="question4"
            required={true}
            name="pregunta4"
            value="si"
            type="radio"
            errors={errors}
            register={register}
          />
          <FormField
            label="NO"
            id="question4"
            required={true}
            name="pregunta4"
            value="no"
            type="radio"
            errors={errors}
            register={register}
          />
        </div>
      </div>

      <div className="mt-4">
        <p>¿Maneja u opera maquinarias de alta precisión?</p>
        <div className="flex justify-around">
          <FormField
            label="SI"
            id="question5"
            required={true}
            name="pregunta5"
            value="si"
            type="radio"
            errors={errors}
            register={register}
          />
          <FormField
            label="NO"
            id="question5"
            required={true}
            name="pregunta5"
            value="no"
            type="radio"
            errors={errors}
            register={register}
          />
        </div>
      </div>
    </>
  );
}

export default MedicalInformationAr;
