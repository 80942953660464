const referenceStatus: Record<number, string> = {
    0: "Pendiente de aprobación de pago",
    1: "Pendiente de cita medica",
    2: "Pendiente de recomendación médica",
    3: "Recomendación médica finalizada",
    4: "Pendiente en Depto. de salud",
    5: "Aprobado",
    6: "En Revisión",
    7: "Entregado"
  };
  
  export default referenceStatus;

  