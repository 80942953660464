import React, { useEffect, useState } from "react";
import FormField from "../../../components/FormFiel";
import { usePasswordToggle } from "../../../hooks/usePasswordToggle";
import { getUsersEmails } from "../../../services/getUsersEmails";
import {
  EMAIL,
  PASSWORD,
  PHONE,
  LASTNAME,
  NAME,
} from "../../../validations/SignUP";

type PropsForm = {
  register: any;
  errors: any;
  watch?: any;
  emailExists: boolean; // Prop para el estado de existencia del correo electrónico
  onEmailExistence: (exists: boolean) => void; // Función para actualizar el estado en el componente padre
};

function SingUp({
  register,
  errors,
  watch,
  emailExists,
  onEmailExistence,
}: PropsForm) {
  const [passwordInputType, toggleIcon] = usePasswordToggle();

  const checkEmailExist = async (email: string) => {
    await getUsersEmails(email).then((res) => {
      onEmailExistence(res); // Actualiza el estado en el componente padre
    });
  };

  const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;

    if (newEmail.length >= 6) {
      await checkEmailExist(newEmail);
    } else {
      onEmailExistence(false);
    }
  };

  return (
    <div className="space-y-2">
      <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
        <div className="xl:w-1/2">
          <FormField
            id="firstName"
            name="nombre"
            required={true}
            type="text"
            maxLength={25}
            minLength={3}
            placeholder="Nombre"
            errors={errors}
            register={register}
            validation={NAME}
          />
        </div>

        <div className="xl:w-1/2">
          <FormField
            id="lastName"
            name="apellido"
            required={true}
            type="text"
            maxLength={20}
            minLength={3}
            placeholder="Apellido"
            errors={errors}
            register={register}
            validation={LASTNAME}
          />
        </div>
      </div>

      <div className="sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
        <div className="flex-grow">
          <FormField
            id="phone"
            name="telefono"
            required={true}
            type="number"
            maxLength={10}
            minLength={8}
            placeholder="Teléfono sin +1"
            errors={errors}
            register={register}
            validation={PHONE}
          />
        </div>
      </div>

      <FormField
        id="email"
        required={true}
        type="email"
        maxLength={35}
        minLength={6}
        placeholder="Correo Electrónico"
        errors={errors}
        register={register}
        name="email"
        validation={EMAIL}
        onChange={handleEmailChange}
      />
      {emailExists && (
        <p className="text-red-500 text-sm mt-2">
          El correo electrónico ya existe en la base de datos.
        </p>
      )}
      <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
        <div className="flex flex-col relative w-full">
          <FormField
            id="password"
            name="contraseña"
            type={passwordInputType as any}
            required={true}
            minLength={8}
            maxLength={16}
            placeholder="Contraseña"
            errors={errors}
            register={register}
            toggleIcon={toggleIcon}
            validation={PASSWORD}
          />
        </div>
      </div>
      <div className="w-full">
        <p className="text-gray-400 text-sm mt-2">
          * La contraseña debe tener al menos 8 caracteres, una mayúscula, una
          minúscula y un número.
        </p>
      </div>

      <FormField
        id="whereMet"
        name="Donde te enteraste de nosotros"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar cómo te enteraste de nosotros" },
          { value: "1", label: "Visitando un dispensario" },
          { value: "2", label: "Por un/a familiar o amigo/a" },
          { value: "3", label: "Página Web" },
          { value: "4", label: "Email" },
          { value: "5", label: "Billboard" },
          { value: "6", label: "Instagram" },
          { value: "7", label: "Facebook" },
          { value: "8", label: "Otro" },
        ]}
      />

      <FormField
        id="termConditions"
        label="Aceptar Terminos y Condiciones."
        name="terminos y condiciones"
        type="checkbox"
        required={true}
        errors={errors}
        errorCheckbox="Debes aceptar los terminos y condiciones"
        register={register}
        toLink="/terms"
      />
    </div>
  );
}

export default SingUp;
