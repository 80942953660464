import { useAuthContext } from "../../contexts/authContext";

type MessageProps = {
  message: string;
  button: any;
};

function Message({ message, button }: MessageProps) {
  const { user } = useAuthContext();

  return (
    <div className="bg-cdgreen rounded-3xl">
      <div className="w-full mx-auto py-10 px-4 z-20 sm:px-6 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl text-white text-center lg:text-left md:text-3xl lg:px-15">
          <div className="flex gap-2 w-full justify-center lg:justify-start">
            
            {user.rol === 2 ? (
            <span className="block capitalize font-extrabold">Hola Doctor</span>
            ) : (
            <span className="block capitalize font-extrabold">Hola</span>
            )}

            <span className="block capitalize font-extrabold">
              {user.firstName} {user.lastName}
            </span>
          </div>
          <span className="hidden sm:block sm:visible text-white text-xl font-semibold">
            {message}
          </span>
        </h2>
        <div className="flex mt-4 lg:mt-0 justify-center md:shrink md:px-3">
          <div className="inline-flex rounded-md shadow">{button}</div>
        </div>
      </div>
    </div>
  );
}

export default Message;
