import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { getPatientsId } from "../../../services/getPatients";
import { getMedicalRecordPr } from "../../../services/getMedicalRecordPr";
import { getPatientss } from "../../../services/getPatients";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import logo from "../../../assets/images/logos/canadoctors/canadoctorsColors.png";
import logoMR from "../../../assets/images/logos/canadoctors/logoCanadoctorsMR.png";
import referenceNames from "../../data/referenceNames";
import { getPaypalPayments } from "../../../services/paypalPayments";
import cityFrom from "../../data/city";

interface PDFRecordPRProps {
  id: number | null;
}

interface MedicalRecordPr {
  [key: string]: any;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#F3F4F6",
    padding: "1.5cm",
  },
  header: {
    marginBottom: "1.5cm",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 220,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: "0.5cm",
  },
  section: {
    marginBottom: "0.5cm",
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: "0.3cm",
  },
  text: {
    fontSize: 10,
    marginBottom: "0.2cm",
    fontWeight: "bold",
  },
});

const PDFRecordPR: React.FC<PDFRecordPRProps> = ({ id }) => {
  const [patient, setPatient] = useState<any | null>(null);
  const [medicalRecordPr, setMedicalRecordPr] =
    useState<MedicalRecordPr | null>(null);

  const [typeTransaction, setTypeTransaction] = useState<any | null>(null);
  const [scheduleDate, setScheduleDate] = useState<any | null>(null);
  const [scheduleRange, setScheduleRange] = useState<any | null>(null);

  useEffect(() => {
    getPatientsId(id).then((data) => {
      setPatient(data);
    });
    getMedicalRecordPr(id).then((data) => {
      setMedicalRecordPr(data);
    });
    getPaypalPayments(id).then((data: any) => {
      setTypeTransaction(data[0].type);
      setScheduleDate(data[0].scheduleDate);
      setScheduleRange(data[0].scheduleRange);
    });
  }, [id]);

  const MyDocument: React.FC = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Medical Record</Text>
          <Image src={logoMR} style={styles.logo} />
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>ID Paciente: {patient?.id}</Text>
          <Text style={styles.text}>
            Dispensario Referente: {referenceNames[patient?.reference]}
          </Text>
          <Text style={styles.text}>
            Tipo de trámite: {typeTransaction || "No Solicitado (falta Pago)"}
          </Text>
          <Text style={styles.text}>
            Fecha preferencial de llamada: {scheduleDate}
          </Text>
          <Text style={styles.text}>
            Rango preferencial:{" "}
            {(scheduleRange === "1" && "8am-12pm") ||
              (scheduleRange === "2" && "12pm-16-pm") ||
              (scheduleRange === "1" && "16-pm-20pm")}
          </Text>
          <Text style={styles.text}>Dr: </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.text}>
            *IMPORTANTE - validar informacion con paciente
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Datos Personales</Text>
          <Text style={styles.text}>
            Nombre Completo: {patient?.lastName} {patient?.firstName}
          </Text>
          <Text style={styles.text}>
            SSN: {medicalRecordPr?.socialSecurity}
            {/*        SSN:{" "}
            {medicalRecordPr?.socialSecurity &&
            typeof medicalRecordPr.socialSecurity === "string"
              ? medicalRecordPr.socialSecurity.substring(
                  0,
                  medicalRecordPr.socialSecurity.length - 4
                ) + "****"
              : "Invalid SSN"} */}
          </Text>
          <Text style={styles.text}>
            Género: {medicalRecordPr?.gender === "M" ? "Masculino" : "Femenino"}
          </Text>
          <Text style={styles.text}>Email: {patient?.email}</Text>
          <Text style={styles.text}>Teléfono: {patient?.phone}</Text>
          <Text style={styles.text}>
            Fecha de Nacimiento: {medicalRecordPr?.birthDate}
          </Text>
          <Text style={styles.text}>Peso: {medicalRecordPr?.weight} Lbs</Text>
          <Text style={styles.text}>
            Altura: {medicalRecordPr?.foots}''{medicalRecordPr?.inches}'
          </Text>
          <Text style={styles.text}>
            Color de ojos: {medicalRecordPr?.eyesColor}
          </Text>
          <Text style={styles.text}>
            Profesión: {medicalRecordPr?.profession}
          </Text>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}> - </Text>
            <Text style={styles.text}>
              Dirección: {medicalRecordPr?.address}
            </Text>
            <Text style={styles.text}>
              Codigo postal: {medicalRecordPr?.zipCode}
            </Text>
            <Text style={styles.text}>
              Ciudad: {cityFrom[medicalRecordPr?.city] || "No indicada"}
            </Text>
          </View>
          {/* Agrega más campos de datos personales según tus necesidades */}
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Datos Clínicos</Text>
          <Text style={styles.text}>
            ¿Está tomando algún medicamento?:{" "}
            {medicalRecordPr?.useMedicines ? "Si" : "No"} - Nombre del
            medicamento:{" "}
            {medicalRecordPr?.medicines ? medicalRecordPr?.medicines : "N/A"}
          </Text>
          <Text style={styles.text}>
            ¿Padece de alguna alergia?:{" "}
            {medicalRecordPr?.haveAllergies ? "Si" : "No"} - Tipo de alergia:{" "}
            {medicalRecordPr?.allergies ? medicalRecordPr?.allergies : "N/A"}
          </Text>
          <Text style={styles.text}>
            ¿Fuma cigarrillos?: {medicalRecordPr?.smoke ? "Si" : "No"} -{" "}
            ¿Cuántos cigarrillos fuma al día?:{" "}
            {medicalRecordPr?.frequenceSmoke
              ? medicalRecordPr?.frequenceSmoke
              : "N/A"}
          </Text>
          <Text style={styles.text}>
            ¿Bebe alcohol?: {medicalRecordPr?.drink ? "Si" : "No"} - ¿Cada
            cuánto bebe alcohol?:{" "}
            {medicalRecordPr?.frecuenceDrink
              ? medicalRecordPr?.frecuenceDrink
              : "N/A"}
          </Text>
          <Text style={styles.text}>
            ¿Que tipo de alcohol?:{" "}
            {medicalRecordPr?.cerveza ? "Cerveza" : "---"} /{" "}
            {medicalRecordPr?.vino ? "Vino" : "---"} /{" "}
            {medicalRecordPr?.whisky ? "Whisky" : "---"}.
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            Condición medica:
            <Text style={styles.text}> {medicalRecordPr?.condition} </Text>
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>Fecha de atencion: </Text>
          <Text style={styles.text}>
            Diagnostico: {medicalRecordPr?.reason}
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink
      document={<MyDocument />}
      fileName={`MR- PI-${patient?.id}- ${patient?.firstName} ${patient?.lastName}.pdf`}
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <button className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
            Descargar PDF
          </button>
        ) : (
          <button className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500">
            Descargar PDF
          </button>
        )
      }
    </PDFDownloadLink>
  );
};

export default PDFRecordPR;
