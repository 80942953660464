import FormField from "../../../components/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

const handleOpNum = (e: React.ChangeEvent<HTMLInputElement>) => {
  const numericValue = e.target.value.replace(/\D/g, "");
  let formattedValue = "";
  if (numericValue.length > 0) {
    formattedValue += numericValue.substr(0, 18);
  }
  if (numericValue.length > 18) {
    formattedValue += "-" + numericValue.substr(18, 26);
  }

  e.target.value = formattedValue;
};

function ATHopNum({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        label="Número de referencia"
        id="orderId"
        required={true}
        name="Número de referencia"
        type="text"
        maxLength={50}
        minLength={10}
        onChange={handleOpNum}
        placeholder="Número de referencia"
        autoComplete={"off"}
        errors={errors}
        register={register}
        //validation={LICNUM}
      />
    </>
  );
}

export default ATHopNum;
