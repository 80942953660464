import React, { useEffect, useState } from "react";
import {
  getDispensaryLocation,
  getDispensaryTotals,
} from "../../services/getDispensary";
import referenceNames from "../data/referenceNames";
import LoadingComponent from "../LoadingComponent";
import { getTransactionStatusCount } from "../../services/totalCounter";
import TransactionStatus from "../Graphics/TransactionStatus";
import referenceStatus from "../data/referenceStatus";
import referenceDispensaryLocation from "../data/referenceDispensaryLocation";
import { useAuthContext } from "../../contexts/authContext";

interface DispensaryTotal {
  [key: string]: number;
}

function TotalCounter() {
  const { user } = useAuthContext();
  const [totalPatients, setTotalPatients] = useState<DispensaryTotal | {}>({});
  const [totalTransactionStatus, setTotalTransactionStatus] = useState<
    DispensaryTotal | {}
  >({});
  const [totalDispensaryLocations, setTotalDispensaryLocations] = useState<
    DispensaryTotal | {}
  >({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getDispensaryTotals()
      .then((res) => {
        setTotalPatients(res);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));

    getTransactionStatusCount()
      .then((transactionStatusCount) => {
        // Convierte el objeto en un array de objetos
        const dataArr = Object.entries(transactionStatusCount).map(
          ([status, count]) => ({
            status: referenceStatus[parseInt(status)],
            count,
          })
        );
        setTotalTransactionStatus(dataArr);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });

    getDispensaryLocation().then((dispensaryLocation) => {
      // Convierte el objeto en un array de objetos
      const dataArr = Object.entries(dispensaryLocation).map(
        ([status, count]) => ({
          status: referenceDispensaryLocation[parseInt(status)],
          count,
        })
      );
      setTotalDispensaryLocations(dataArr);
    });
  }, []);

  return (
    <div className="mt-2 w-full lg:px-6">
      <h1 className="font-bold text-3xl text-black">Total de Pacientes</h1>
      {!isLoading ? (
        <div>
          <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4">
            {Object.entries(totalPatients).map(([dispensary, total]) => (
              <div
                key={dispensary}
                className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 "
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                      {total}
                    </span>
                    <h3 className="text-base font-normal text-gray-500">
                      {referenceNames[Number(dispensary)] || "No definido"}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10">
            {" "}
            <TransactionStatus data={totalTransactionStatus} />
            <TransactionStatus data={totalDispensaryLocations} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <LoadingComponent />
        </div>
      )}
    </div>
  );
}

export default TotalCounter;
