const baseURL = "https://canadoctors.herokuapp.com/transactions";

const getTransactionStatusCount = async () => {
  const response = await fetch(`${baseURL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const data = await response.json();

  const transactionStatusCount = {};

  data.forEach((transaction) => {
    const status = transaction.status;
    if (transactionStatusCount[status]) {
      transactionStatusCount[status]++;
    } else {
      transactionStatusCount[status] = 1;
    }
  });

  return transactionStatusCount;
};

export { getTransactionStatusCount };
