import { useAuthContext } from "../../../contexts/authContext";

type UserCardProps = {
  state: string;
};

function UserCard({ state }: UserCardProps) {
  const { user } = useAuthContext();

  let displayName = `${user.firstName} ${user.lastName}`;
  function primeraUltima(displayName: string) {
    let palabras = displayName.split(/\s+/);
    let primerasLetras = palabras.map((palabra) => palabra[0]);
    let union = primerasLetras[0] + primerasLetras[primerasLetras.length - 1];

    return union;
  }

  const country = () => {
    switch (user.country) {
      case "AR":
        return "Argentina";
      case "PR":
        return "Puerto Rico";

      default:
        return "No definido";
    }
  };

  return (
    <div className="w-full">
      <div className="h-80 mx-auto shadow-xl rounded-xl bg-white mb-6">
        <div className="flex justify-center pt-6">
          <div className="flex justify-center items-center w-20 h-20 rounded-full shadow-lg bg-cdgreen">
            <span className="text-5xl font-bold text-white uppercase">
              {primeraUltima(displayName)}
            </span>
          </div>
        </div>
        <div className="text-center mt-2">
          <h3 className="text-2xl text-slate-700 font-bold leading-normal mb-1 capitalize">
            {`${user.firstName} ${user.lastName}`}
          </h3>
          <div className="text-sm mt-2 mb-2 text-slate-400 font-bold uppercase">
            {country()}
          </div>
        </div>

        <div className="text-center mt-2 font-light text-sm">{user.phone}</div>

        <div className="text-center mt-2 font-light text-sm">{user.email}</div>

        <hr className="mt-6" />
        <div className="h-16 flex items-center justify-center">
          {state === "AUTORIZADO" ? (
            <span className="px-8 py-1 text-base rounded-full text-green-600 bg-green-200">
              Autorizado
            </span>
          ) : (
            <span className="px-8 py-1 text-base rounded-full text-red-600  bg-red-200 ">
              No Autorizado
            </span>
          )}
        </div>
        <hr className="sm:hidden mb-6" />
      </div>
    </div>
  );
}

export default UserCard;
