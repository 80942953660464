import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import firebase, { db } from "./firebase";
import {
  collection,
  getDocs,
  getDoc,
  query,
  doc,
  addDoc,
  deleteDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const saveImg = async (files, id) => {
  const storage = getStorage();
  const urls = [];

  const cuidadetiRef = ref(storage, "cuidadetiTetra/");
  const userFolderRef = ref(cuidadetiRef, id + "/");

  const newFileNames = ["license.jpeg", "profile.jpeg", "doc_id.jpeg"];
  const totalFiles = Math.max(newFileNames.length, files.length);

  for (let i = 0; i < totalFiles; i++) {
    const fileName = newFileNames[i];
    const file = files[i] || null; // Usa null si no hay archivo en esa posición

    if (file !== null) {
      const storageRef = ref(userFolderRef, fileName);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      urls.push(url);
    } else {
      urls.push(""); // Agrega un elemento nulo para mantener el orden
    }
  }

  return urls;
};

export const getImg = async (id) => {
  const storage = getStorage();
  const cuidadetiRef = ref(storage, "cuidadetiTetra/");
  const userFolderRef = ref(cuidadetiRef, id + "/");

  const licenseRef = ref(userFolderRef, "license.jpeg");
  const profileRef = ref(userFolderRef, "profile.jpeg");
  const doc_idRef = ref(userFolderRef, "doc_id.jpeg");

  const downloadPromises = [];

  // Verificar si el elemento 'license' existe y obtener su URL si existe
  if (await exists(licenseRef)) {
    downloadPromises.push(getDownloadURL(licenseRef));
  } else {
    downloadPromises.push(null); // Agregar null en caso de que no exista 'license'
  }

  // Verificar si el elemento 'profile' existe y obtener su URL si existe
  if (await exists(profileRef)) {
    downloadPromises.push(getDownloadURL(profileRef));
  } else {
    downloadPromises.push(null); // Agregar null en caso de que no exista 'profile'
  }

  // Verificar si el elemento 'doc_id' existe y obtener su URL si existe
  if (await exists(doc_idRef)) {
    downloadPromises.push(getDownloadURL(doc_idRef));
  } else {
    downloadPromises.push(null); // Agregar null en caso de que no exista 'doc_id'
  }

  // Esperar a que todas las promesas de descarga se completen
  const downloadUrls = await Promise.all(downloadPromises);

  return downloadUrls;
};

// Función auxiliar para verificar si una referencia existe
const exists = async (ref) => {
  try {
    await getDownloadURL(ref);
    return true; // La referencia existe
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      return false; // La referencia no existe
    } else {
      throw error; // Ocurrió un error diferente
    }
  }
};
