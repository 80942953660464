const baseURL = "https://canadoctors.herokuapp.com/transactions";

const postEvertec = async (datos) => {
  const response = await fetch(baseURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
    body: JSON.stringify({ data: datos }),
  });

  return response.json();
};

export { postEvertec };
