import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import { getPaypalPayments } from "../../services/paypalPayments";
import CardTransactions from "../../components/Transactions/CardTransactions";
import TableTransactions from "../../components/Transactions/TableTransactions";
import AlertsMenssages from "../../components/AlertsMenssages";

function TransactionHistory() {
  const { user } = useAuthContext();
  const [paypalPayments, setPaypalPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPayments = async () => {
      await getPaypalPayments(user.id).then((res) => {
        setPaypalPayments(res);
        setIsLoading(false);
      });
    };
    getPayments();
  }, [user.id]);

  if (isLoading) {
    return (
      <>
        <div className="text-center p-8">
          <p className="font-bold text-4xl text-black">Mis Pagos</p>
        </div>

        <div className="w-full flex justify-center pt-8">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-[#00A099]/90 border-8 h-20 w-20"></div>
        </div>
      </>
    );
  }

  if (!paypalPayments || paypalPayments.length === 0) {
    return (
      <>
        <div className="text-center p-8">
          <p className="font-bold text-4xl text-black">Mis Pagos</p>
          <div className="pt-4">
            <AlertsMenssages type="warning" message="No hay pagos realizados" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="text-center p-8">
        <p className="font-bold text-4xl text-black">Mis Pagos</p>
      </div>
      <div>
        {paypalPayments && (
          <>
            <div className="hidden md:visible md:flex">
              <TableTransactions tableData={paypalPayments} />
            </div>
            <div className="visible md:hidden">
              <CardTransactions cardData={paypalPayments} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TransactionHistory;
