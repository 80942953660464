const cityFrom : Record<string, string> = {

    "": "Seleccione una ciudad",
    "34": "Adjuntas",
    "26": "Aguada",
    "01": "Aguadilla",
    "45": "Aguas Buenas",
    "68": "Aibonito",
    "28": "Añasco",
    "06": "Arecibo",
    "72": "Arroyo",
    "07": "Barceloneta",
    "41": "Barranquitas",
    "15": "Bayamón",
    "55": "Cabo Rojo",
    "46": "Caguas",
    "04": "Camuy",
    "80": "Canóvanas",
    "20": "Carolina",
    "14": "Cataño",
    "70": "Cayey",
    "53": "Ceiba",
    "37": "Ciales",
    "44": "Cidra",
    "66": "Coamo",
    "43": "Comerío",
    "40": "Corozal",
    "77": "Culebra",
    "11": "Dorado",
    "24": "Fajardo",
    "81": "Florida",
    "59": "Guánica",
    "71": "Guayama",
    "61": "Guayanilla",
    "16": "Guaynabo",
    "47": "Gurabo",
    "05": "Hatillo",
    "54": "Hormigueros",
    "51": "Humacao",
    "02": "Isabela",
    "36": "Jayuya",
    "64": "Juana Díaz",
    "49": "Juncos",
    "57": "Lajas",
    "33": "Lares",
    "31": "Las Marías",
    "50": "Las Piedras",
    "21": "Loíza",
    "23": "Luquillo",
    "08": "Manatí",
    "32": "Maricao",
    "74": "Maunabo",
    "29": "Mayagüez",
    "27": "Moca",
    "38": "Morovis",
    "52": "Naguabo",
    "42": "Naranjito",
    "39": "Orocovis",
    "73": "Patillas",
    "62": "Peñuelas",
    "63": "Ponce",
    "03": "Quebradillas",
    "25": "Rincón",
    "22": "Río Grande",
    "58": "Sabana Grande",
    "69": "Salinas",
    "56": "San Germán",
    "79": "San Juan",
    "48": "San Lorenzo",
    "30": "San Sebastián",
    "67": "Santa Isabel",
    "12": "Toa Alta",
    "13": "Toa Baja",
    "19": "Trujillo Alto",
    "35": "Utuado",
    "10": "Vega Alta",
    "09": "Vega Baja",
    "76": "Vieques",
    "65": "Villalba",
    "75": "Yabucoa",
    "60": "Yauco",

}

export default cityFrom;