type paypalPaymentsData = {
  cardData: any;
};

type cardData = {
  id: string;
  date: string;
  type: string;
  method: string;
  amount: number;
  orderId: string;
  state: string;
};

function CardTransactions({ cardData }: paypalPaymentsData) {
  return (
    <>
      {cardData.map((cardData: cardData) => (
        <div className="flex flex-col mt-2 w-full" key={cardData.id}>
          <div className="bg-white border p-4 rounded-2xl hover:bg-gray-100">
            <div>
              <p className="text-xs text-gray-400 text-left font-light tracking-widest">
                {cardData.date?.split("T")[0].split("-").reverse().join("/")}
              </p>
            </div>
            <div className="flex flex-row justify-between py-1 px-2.5">
              <p className="text-md font-bold tracking-wide capitalize">
                {cardData.type}
              </p>
              <p className="text-lg font-bold tracking-wide">
                ${cardData.amount}
              </p>
            </div>
            <div className="px-2.5 text-sm text-gray-400 text-left font-light tracking-widest capitalize">
              <p>{cardData.method}</p>
            </div>
            <div className="flex flex-row justify-between px-2.5 text-gray-500">
              <p>{cardData.orderId}</p>
              <div className="bg-green-100 rounded-lg px-2 text-cdgreenlight">
                {cardData.state}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CardTransactions;
