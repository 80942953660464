import { useEffect, useState } from "react";
import {
  getPatientsByReferenceAndStatus,
  getDispensaryLocation,
} from "../../services/getDispensary";
import LoadingComponent from "../LoadingComponent";
import TransactionStatus from "../Graphics/TransactionStatus";
import referenceStatus from "../data/referenceStatus";
import { useAuthContext } from "../../contexts/authContext";
import referenceDispensaryLocation from "../data/referenceDispensaryLocation";

interface DispensaryTotal {
  [key: string]: number;
}

function TotalDispensary() {
  const { user } = useAuthContext();
  const [totalPatients, setTotalPatients] = useState<DispensaryTotal | {}>({});
  const [totalTransactionStatus, setTotalTransactionStatus] = useState<
    DispensaryTotal | {}
  >({});
  const [totalDispensaryLocations, setTotalDispensaryLocations] = useState<
    DispensaryTotal | {}
  >({});

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Función para calcular el total de pacientes
  const calculateTotalPatients = (totalPatients: DispensaryTotal) => {
    let total = 0;
    for (const key in totalPatients) {
      if (totalPatients.hasOwnProperty(key)) {
        total += totalPatients[key];
      }
    }
    return total;
  };

  useEffect(() => {
    getPatientsByReferenceAndStatus(user.reference)
      .then((transactionStatusCount) => {
        // Convierte el objeto en un array de objetos
        const dataArr = Object.entries(transactionStatusCount).map(
          ([status, count]) => ({
            status: referenceStatus[parseInt(status)],
            count,
          })
        );
        setTotalTransactionStatus(dataArr);
        setTotalPatients(transactionStatusCount);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener los datos:", error);
      });

    getDispensaryLocation().then((dispensaryLocation) => {
      // Convierte el objeto en un array de objetos
      const dataArr = Object.entries(dispensaryLocation).map(
        ([status, count]) => ({
          status: referenceDispensaryLocation[parseInt(status)],
          count,
        })
      );
      setTotalDispensaryLocations(dataArr);
    });
  }, []);

  // Calcular el total de pacientes
  const totalPatientsCount = calculateTotalPatients(totalPatients);

  return (
    <div className="mt-2 w-full lg:px-6">
      <h1 className="font-bold text-3xl text-black">Total de Pacientes</h1>
      {!isLoading ? (
        <div>
          <div className="mt-4 w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
            <div
              className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8"
              key="total"
            >
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                    {totalPatientsCount}
                  </span>
                  <h3 className="text-base font-normal text-gray-500">
                    Total de Pacientes
                  </h3>
                </div>
              </div>
            </div>
            {Object.entries(totalPatients).map(([dispensary, total]) => (
              <div
                key={dispensary}
                className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 "
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
                      {total}
                    </span>
                    <h3 className="text-base font-normal text-gray-500">
                      {referenceStatus[Number(dispensary)] || "No definido"}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10">
            <TransactionStatus data={totalTransactionStatus} />
            {user.rol === 3 && user.reference === 6 && (
              <TransactionStatus data={totalDispensaryLocations} />
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <LoadingComponent />
        </div>
      )}
    </div>
  );
}

export default TotalDispensary;
