import Logo from "../../assets/images/logos/canadoctors/canadoctorsColors.svg";

function TermAndCondition() {
  return (
    <div className="w-full h-[100vh] bg-[#F3F4F6] overflow-hidden relative flex">
      <div className="absolute overflow-auto inset-0 bg-gradient-to-b">
        <div className="flex h-auto w-full items-center justify-center container mx-auto px-8">
          <div className="max-w-4xl text-center">
            <div className="flex w-full mt-6 mb-4 justify-center">
              <img width={"400px"} src={Logo} alt="logo canadoctors" />
            </div>

            <h2 className="text-lg font-bold  sm:text-2xl md:text-3xl lg:text-xl pt-2 pb-2">
              <span className="block">
                TERMINOS Y CONDICIONES Y CONSENTIMIENTO DE USO DE LA PLATAFORMA
                WEB Y APLICACION
              </span>
            </h2>

            <div className="text-justify pb-10">
              {" "}
              <p>
                Este documento describe los Términos y Condiciones de uso que se
                aplicarán a toda persona que utilice en su calidad de usuario,
                paciente, profesional de la Salud, institución de salud
                (consultorio, clínica, laboratorio, centro de imágenes,
                farmacia, dispensario etc) de la aplicación denominada
                “Canadoctors” (en adelante, la “Aplicación”), de propiedad de
                ENCOM LLC (en adelante, “LA EMPRESA”) con cuit Nro. , con
                domicilio legal en . Toda persona natural o jurídica que desee
                ser admitido como usuario de la presente página web y aplicativo
                móvil en cualquiera de sus funciones, previa y obligatoriamente
                deberá leer, entender, y aceptar todas las condiciones
                establecidas en estos Términos y condiciones, caso contrario
                deberá abstenerse de utilizar la presente aplicación.
                Entendiéndose como aceptación de los Términos y condiciones el
                haber dado clic sobre el botón de aceptar Términos y
                condiciones. DEFINICIONES: A los efectos de una mejor
                interpretación de los presentes Términos y condiciones, los
                términos que comienzan en mayúscula (excepto cuando fueren
                empleados a fin de iniciar una oración o como nombre propio),
                tienen los significados expresamente definidos a continuación, o
                en el texto de los Términos y condiciones: Usuario: Persona
                capaz, con facultades suficientes en los términos establecidos
                por las leyes del país del usuario que accede a la plataforma
                Canadoctors. Paciente: Persona natural que es inscrita en
                Canadoctors para recibir atención por parte de un profesional de
                la salud en cualquiera de sus formas. Paciente menor de edad:
                Niño o Niña menor de 21 años, que es representado por una
                persona capaz mayor de edad. <br /> <br />
                Médico: Profesional Médico, con título habilitante para ejercer
                la medicina debidamente matriculado ante el Colegio del país
                donde ejerza la profesión y registrado como tal en la Base de
                Datos de la presente web o Aplicación móvil. Profesional de la
                Salud: Médico, Psicólogo, Nutricionista, Odontólogo o
                Licenciado/a: Profesional de salud debidamente colegiado en su
                colegio correspondiente, autorizado para trabajar en el
                territorio nacional donde se desempeñe y que se haya registrado
                como tal en nuestra plataforma Canadoctors en la base de datos.
                Institución de salud: se refiere a la clínica, consultorio,
                Centro de Ayuda diagnostica como laboratorio, centro de imágenes
                (rayos x, resonancia, tomografía, ecografía) dispensario etc que
                se hayan registrado como tal a través de Canadoctors en nuestra
                base de datos. Farmacia:Establecimiento donde se preparan y
                venden medicamentos, debidamente autorizado por el Ministerio de
                Salud. <br /> <br />
                Dispensario: Se refiere a un establecimiento autorizado y que
                posee una licencia o autorización vigente emitida por la junta
                que lo autoriza a almacenar y dispensar el Cannabis Medicinal.
                Consulta presencial: Es la atención de Salud brindada
                presencialmente por un profesional de la salud a un paciente con
                el objetivo de diagnóstico, pronóstico y tratamiento, las cuales
                en ningún caso deberán referirse a una Urgencia y/o Emergencia.
                Tele Consultas: Aquellas atenciones que se realizan en esta
                plataforma, las cuales en ningún caso deberán referirse a una
                Urgencia y/o Emergencia y que no reemplazan a la consulta
                presencial. Base de Datos: Es el registro que llevará el
                Propietario de los datos ingresados por los Usuarios y/o Médicos
                de acuerdo a ley. <br /> <br />
                Videollamada: es referida al contacto a través de una llamada
                virtual mediante el cual se conectan por video profesional de la
                salud -paciente o profesional - profesional, para realizar una
                orientación, Teleconsulta etc. Chat médico: Es la participación
                en los foros, o mediante el escrito (chat) a su médico o
                profesional de la salud para realizar preguntas sobre alguna
                inquietud, al cual el profesional de la salud puede responder
                para orientar al usuario. Soporte Técnico Informático:
                Corresponde a la asesoría, formación y resolución de dudas
                respecto a la operatoria del sistema de conexión Canadoctors. 1)
                ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES: La utilización de la
                página web y aplicativo móvil Canadoctors por parte de un
                Usuario (Médico, profesional de la Salud, paciente, Institución
                de salud), otorga una declaración expresa y fehaciente de haber
                leído y aceptado plena e incondicionalmente todo lo establecido
                en los presentes Términos y condiciones, siendo esto, requisito
                indispensable y sin excepción alguna para la utilización
                referida Aplicación web y móvil. Si el Usuario no lee, acepta y
                comprende los Términos y condiciones aquí detallados, los cuales
                tienen carácter obligatorio y vinculante, deberá abstenerse de
                utilizar la presente Aplicación. LA EMPRESA se reserva el
                derecho de modificar los Términos y condiciones en cualquier
                momento y sin previo aviso, razón por la cual es obligación del
                Usuario revisar periódicamente estos Términos y condiciones. y
                otorgar su aceptación al momento del ingreso. 2) CONTENIDO Y
                FUNCIONES DE LA APLICACIÓN: La función de la Aplicación es
                proporcionar un sistema de conexión entre los involucrados,
                siendo parte de éste las reservas de cita presenciales, Tele
                consulta o video llamadas, chat, manejo de órdenes, recetas y
                Licencias, así como de resultados de exámenes auxiliares. Por lo
                que de ningún modo y en ninguno de los casos podrá interpretarse
                que la Aplicación web o móvil y/o LA EMPRESA; practica y/o
                ejerce la medicina; y/o es un proveedor de salud.
                Consecuentemente, de ningún modo puede interpretarse que por
                medio de la misma LA EMPRESA responde consultas médicas; y/o da
                consejos médicos y/o de tratamiento y/o practica diagnóstico
                alguno, y/o prescribe medicamentos, y/o otros productos para el
                cuidado de la salud. Cada Usuario seleccionará, libremente un
                Médico, profesional de salud o entidad de salud, siendo este
                profesional elegido, el único que podrá proveer a través de la
                Aplicación el consejo médico de su propio criterio, y por lo
                tanto, constituyéndose como el único y exclusivo responsable por
                el consejo emitido. Ni LA EMPRESA, ni la Aplicación,
                recomiendan, y/o apoyan, y/o promocionan, a ningún Médico
                específico, ni tampoco recomiendan medicamentos, y/o
                dispositivos, y/o productos, y/o servicios, y/o médicos, y/o
                profesionales de la Salud o y/o instituciones médicas que puedan
                ser recomendados y/o mencionados por un profesional de la Salud
                y/o que puedan ser referenciados al utilizar la Aplicación. El
                usuario deberá entender, aceptar, comunicar, y reconocer que el
                uso de la Aplicación NO reemplaza a una consulta médica
                presencial bajo ningún punto de vista y en caso de cualquier
                Urgencia y/o Emergencia se deberá aconsejar al Usuario
                comunicarse con el sistema de emergencia local para su
                tratamiento. 3) CONDICIONES DE USO: El Usuario utilizará la
                Aplicación exclusivamente para su uso personal y para los fines
                que corresponde dentro del territorio de uso. Queda expresamente
                prohibida la reproducción total o parcial de los contenidos
                difundidos, protegidos por las leyes de propiedad intelectual,
                así como el uso comercial del contenido de la aplicación, su
                venta o distribución a terceros, o su modificación. <br />
                Mediante la aceptación de los presentes Términos y condiciones
                el usuario se compromete a utilizar la Aplicación correctamente,
                respetando todo lo establecido en los presentes Términos y
                condiciones. El usuario declara y reconoce expresamente que será
                el único responsable por cualquier daño ocasionado por la
                utilización indebida de la presente aplicación, manteniendo
                completamente indemne a LA EMPRESA frente a cualquier reclamo
                que pueda ocasionarse en virtud de la utilización de la misma.
                El usuario se compromete a respetar las condiciones de uso, y
                salva guardar de cualquier forma siempre el bienestar del
                paciente. 4) REGISTRO PREVIO OBLIGATORIO Y RESPONSABILIDADES: Es
                obligatorio para todos los Usuarios sin excepción alguna
                completar el formulario de registro en todos sus campos con
                datos válidos y verídicos, como requisito previo para poder
                utilizar la Aplicación. El usuario deberá completar el
                formulario con su información personal y profesional y/o
                empresarial, de manera exacta, precisa y verdadera (en adelante,
                los “Datos Personales”), obligándose expresamente a actualizar
                los datos personales en caso de modificarse algunos de ellos,
                constituyendo la presentación del mismo una declaración jurada
                formal de veracidad, exactitud y precisión de los Datos
                Personales insertos. LA EMPRESA podrá utilizar diversos medios
                para identificar a los Médicos, profesionales de la Salud o
                Instituciones de la Salud, pero NO se responsabilizará en ningún
                caso por la certeza y/o veracidad de los Datos Personales
                provistos por ellos. Todos los usuarios garantizarán y
                responderán en todos los casos, por la veracidad, exactitud,
                vigencia, y autenticidad de los datos personales por ellos
                ingresados. Con el debido registro en los términos relatados
                precedentemente, cada Médico adquiere una cuenta personal (en
                adelante, la “Cuenta”), a la cual podrá acceder, o bien mediante
                el ingreso de su nombre y clave de seguridad personal por él
                elegida (en adelante, la “Clave de Seguridad”) y/o a través del
                teléfono celular que se encuentre vinculado a la cuenta. Los
                Médicos, profesionales de la Salud o Instituciones de la Salud,
                se obligan a mantener la confidencialidad de su Clave de
                Seguridad, haciéndose responsable exclusivo de cualquier uso de
                su Cuenta. La Cuenta es personal, única e intransferible,
                estando terminantemente prohibido que un mismo Profesional de la
                Salud registre o posea más de una Cuenta a su nombre. En caso
                que LA EMPRESA detecte distintas Cuentas que contengan datos
                coincidentes y/o relacionados, podrá cancelarlas, suspenderlas o
                inhabilitarlas sin previo aviso. El Profesional de la Salud será
                el único responsable por todas las Consultas Presenciales
                efectuadas desde su Cuenta, así como de las diversas formas de
                comunicación con el paciente, pues el acceso a la misma está
                restringido al ingreso y uso de la Clave de Seguridad, de
                conocimiento exclusivo del Profesional de la Salud y/o al
                ingreso a través del teléfono celular vinculado a la cuenta, de
                uso personal del Profesional de la Salud. Todos los
                profesionales de la Salud o Instituciones de la Salud, están
                obligados a notificar a LA EMPRESA en forma inmediata y por
                medio idóneo y fehaciente, cualquier uso no autorizado de su
                Cuenta, así como el ingreso por terceros no autorizados a la
                misma. LA EMPRESA se reserva el derecho de rechazar cualquier
                solicitud de registro. Todos los médicos, profesionales de la
                Salud o Instituciones de la Salud, sin excepción alguna, durante
                todo el tiempo en el que se encuentren suscriptos a la presente
                Aplicación, y como condición esencial para el uso de la misma,
                se comprometen a mantener contratado y plenamente vigente, un
                Seguro para el ejercicio de la Profesión y Mala Praxis. En caso
                de que el Seguro por cualquier motivo sea dado de baja, el
                Médico, profesional de la Salud o Instituciones de la Salud,
                deberán abstenerse de utilizar la Aplicación. Como condición
                esencial para el registro y alta de la cuenta, el Médico,
                profesionales de la Salud o Instituciones de la Salud, deberán
                remitir a LA EMPRESA al momento de solicitar la misma copia y/o
                fotografía de su registros habilitante; caso contrario LA
                EMPRESA podría proceder a suspender la activación hasta tanto se
                cumpla con este requisito. Para todo evento, la aceptación de
                los presentes Términos y condiciones constituye una declaración
                jurada de el Médico, profesional de la Salud o Instituciones de
                la Salud, acerca de su capacidad jurídica para ejercer la
                medicina y los servicios de salud, así como la habilitación
                profesional de acuerdo a las leyes del país aplicables a la
                materia. Los Médicos, profesionales de la Salud o Instituciones
                de la Salud, tienen responsabilidad absoluta de las
                consecuencias que deriven de la atención (contacto mediante
                Consulta presencial, Tele consulta, o chat) con su paciente,
                basadas en sus indicaciones mediante tratamiento y seguimiento,
                etc. El paciente registrado como tal, se compromete a respetar
                las opciones de los demás usuarios, así como aceptar el uso de
                su información personal únicamente para fines médicos y dentro
                de la plataforma Digital Canadoctors, de lo cual, se deja
                constancia y otorga su aceptación tras la aceptación de los
                Términos y condiciones. Mediante el registro y aceptación de los
                Términos y condiciones, el paciente autoriza a la aplicación web
                y móvil Canadoctors a que sus datos sean manejados por sus
                médicos en cuanto a su atención personalizada, además de
                autorizar la difusión de la receta médica indicada por su médico
                a las farmacias y dispensarios autorizados para que pueda ser
                atendido en los lugares autorizados y adscritos a la plataforma
                Canadoctors. Además autoriza la difusión mediante los diversos
                medios con los que cuenta LA EMPRESA las órdenes de exámenes
                auxiliares indicadas por su médico a los diversos centros de
                exámenes auxiliares afiliados, para poder ser atendidos y
                realizados los mismos, así como autorizar a los dichos centros
                afiliados a LA EMPRESA para poder subir los resultados de
                exámenes que deriven de estas órdenes en la plataforma de
                Canadoctors para poder ser vistos por dichos centros, por el
                médico y el mismo paciente. Las instituciones de Salud
                registradas como tal, Aceptan el manejo de datos de los médicos
                y pacientes, así como información de salud que se le brinda, con
                fines únicos y exclusivamente para la salud y en bien del
                paciente. Manteniendo la privacidad debida respecto a los datos
                personales y sensibles de el paciente según ley. Usando A
                Canadoctors únicamente como medio de comunicación e
                intermediario, sin que esto tenga que involucrar a LA EMPRESA
                propietaria de la Plataforma Digital como parte de la atención
                médica, ni participación en la respuesta de resultados. Las
                Instituciones de salud registradas y que aceptan nuestros
                Términos y condiciones, se comprometen a manejar datos de
                médicos y pacientes, de los cuales se comprometen a no divulgar
                información no autorizada por el paciente, y únicamente dentro
                del sistema de Canadoctors. De esta manera se hacen responsables
                del uso de información médica, de pacientes y de la información
                subida por ellos mismos, siendo los únicos responsables de la
                veracidad de sus datos. Las farmacias registradas y que han
                aceptado los Términos y condiciones tendrán acceso a la
                visualización de las recetas electrónicas emitidas por los
                médicos, y bajo la autorización del paciente podrán ser vistas,
                descargadas e impresas para su correcto uso; de esta manera se
                comprometen a no divulgar dicha información médico legal, del
                cual es propietario únicamente el paciente. Los dispensarios
                registrados y que han aceptado los Términos y condiciones
                tendrán acceso a la visualización de las licencias y recetas
                electrónicas emitidas por los médicos, y bajo la autorización
                del paciente podrán ser vistas, descargadas e impresas para su
                correcto uso; de esta manera se comprometen a no divulgar dicha
                información médico legal, del cual es propietario únicamente el
                paciente. Los centros de apoyo al diagnóstico en general
                (laboratorios, centros de imágenes, etc), tendrán acceso a las
                órdenes de exámenes auxiliares electrónicas emitidas por el
                médico al paciente, al cual podrán tener acceso únicamente con
                autorización del paciente, y podrán subir los resultados de sus
                exámenes en la plataforma autorizados a ellos, para ser visto
                por paciente y médico; por tal razón se comprometen a manejar
                dicha información de manera idónea, y sin difundir dicha
                información que le pertenece únicamente al paciente. De esta
                manera el centro de exámenes auxiliares es el único responsable
                de la veracidad de la información subida a la plataforma
                Canadoctors, como resultado que deriven de las órdenes médicas y
                exámenes realizados. Por tales explicaciones, LA EMPRESA queda
                exenta de responsabilidades por el uso de su plataforma digital,
                ni se involucra en los resultados que derivan del uso de las
                diversas conexiones de salud, incluidos recetas y órdenes de
                exámenes auxiliares, ya que LA EMPRESA es únicamente un
                intercomunicador más no un ente de atención de salud. 5)
                INVITACIONES A TERCEROS: Los Médicos, profesionales de la Salud,
                Instituciones de la Salud, o dispensarios podrán enviar
                invitaciones sugiriendo a Pacientes que suscriban a la
                Aplicación en su calidad de Usuarios mediante todos los medios
                ofrecidos por la aplicación, incluyendo sin implicar limitación:
                correo electrónico, WhatsApp, y SMS. Los Médicos, profesionales
                de la Salud o Instituciones de la Salud, podrán elegir enviar
                las invitaciones anónimamente, es decir sin que en ella figuren
                sus datos de contacto como Médico registrado en LA EMPRESA, o
                enviarlas juntamente con sus datos personales registrados en la
                aplicación. En caso de que los Médicos, profesionales de la
                Salud o Instituciones de la Salud, deseen enviar la invitación
                incluyendo sus datos personales utilizando mensajes SMS, el
                costo de cada mensaje SMS enviado deberá ser asumido por el
                solicitante, y será cobrado directamente por el operador móvil
                que tenga contratado. En caso que los Médicos, profesionales de
                la Salud, Instituciones de la Salud o dispensarios inviten a
                ciertos Pacientes utilizando mensaje SMS, pero bajo la modalidad
                “anónimo”, es decir sin enviar sus datos personales de contacto,
                el SMS enviado desde un servidor de la Aplicación asumiendo LA
                EMPRESA el costo del mensaje. 6) ATENCIÓN Y PLAZO DE RESPUESTA
                DE LAS CONSULTAS: a) Cada Médico deberá informar a través de su
                cuenta, el rango horario dentro del cual atenderá consultas (en
                adelante, el “Rango Horario”). Mediante la aceptación de los
                presentes, el Médico se compromete a respetar sin excepción
                alguna los plazos establecidos precedentemente. 7) INEXISTENCIA
                DE RELACION LABORAL: Mediante la aceptación de los presentes
                Términos y condiciones, el Profesional de la Salud declara que
                es un profesional independiente y que ejerce su profesión en
                forma autónoma prestando servicios como tal. En ese sentido
                declara que en ninguno de los casos se entenderá que entre LA
                EMPRESA y el Profesional de la Salud existe una relación de
                carácter laboral de ningún tipo. 8) INEXISTENCIA DE
                RESPONSABILIDAD DE LA EMPRESA: Mediante la aceptación de los
                presentes Términos y condiciones el usuario o su representante
                legal acepta y reconoce que LA EMPRESA solamente pone a
                disposición una forma de contacto virtual entre los Usuarios. LA
                EMPRESA en ningún caso será responsable por los diagnósticos y/o
                consejos profesionales que el Profesional de la Salud proponga,
                por lo que, al aceptar los presentes Términos y condiciones, el
                Profesional de la Salud y demás usuarios se comprometen a
                mantener indemne e indemnizar a LA EMPRESA, y/o sus
                funcionarios, y/o directores, y/o empleados, y/o representantes,
                frente a cualquier reclamo originado en el uso de la presente
                Aplicación. En caso de que LA EMPRESA tuviera que afrontar una
                sanción, multa, condena o indemnización de cualquier tipo que
                hubiera provocado el Profesional de la Salud por el
                incumplimiento de cualquiera de los puntos establecidos en la
                presente Términos y condiciones, el Profesional de la Salud
                deberá reembolsar la cantidad que se hubiere abonado por tal
                motivo en un plazo de 30 (treinta) días de recibida la
                notificación que se lo indique. Asimismo, LA EMPRESA se
                encuentra facultada para solicitar el desvío de la facturación a
                fin de compensar las sumas que haya tenido que abonar y/o
                retener las sumas en forma preventiva ante la eventualidad de un
                reclamo, cualquiera que sea su naturaleza, objeto, o monto. De
                igual forma LA EMPRESA no se hace responsable de los acuerdos
                que entre el Profesional de la Salud y paciente puedan arribar
                durante, previa o posterior a la atención médica, ya sea
                virtual, física en consultorio o domicilio. Para el cual tanto
                paciente como Profesional de la Salud debe verificar y
                asegurarse de la veracidad por diversos medios existentes en la
                actualidad. De esta forma recalcamos que Canadoctors, es
                únicamente un sistema de conexión y comunicación, participando
                LA EMPRESA únicamente como intermediario entre los informadores,
                más no siendo parte en ninguna de sus formas. Los Médicos,
                profesionales de la Salud, Instituciones de la Salud y
                dispensarios se comprometen a emitir el recibo por
                honorarios/factura correspondiente a la atención médica con el
                paciente. De igual forma LA EMPRESA no se hace responsable por
                cualquier incumplimiento de las obligaciones Tributarias o de
                salud, de las Instituciones de Salud públicas o privadas, la
                farmacia, laboratorio, dispensario y/o centro de Apoyo al
                Diagnóstico (del tipo que fuere) y/o de cualquier prestador de
                servicios que intervenga por consejo y/o prescripción del
                Profesional de la Salud, como así cualquier daño y/o perjuicio
                ocasionado por cualesquiera de éstos, respecto del Usuario y/o
                el Paciente. LA EMPRESA no es dueño de locales de consultorios,
                por tal motivo no se responsabiliza de la inexistencia de algún
                consultorio, informado como real por el Profesional de la Salud,
                clínica o centro de exámenes auxiliares. El paciente al aceptar
                los términos y condiciones, acepta recibir orientación si así lo
                requiere por mensajes vía chat, foros o videollamada. <br />{" "}
                <br />
                9) FALLAS TÉCNICAS EN LA APLICACIÓN: LA EMPRESA no se
                responsabiliza por cualquier daño, perjuicio o pérdida al
                Profesional de la Salud causados por fallas técnicas en la
                Aplicación, en el servidor o en Internet. LA EMPRESA tampoco
                será responsable por cualquier virus que pudiera infectar el
                equipo del como consecuencia del acceso o a raíz de cualquier
                transferencia de datos, archivos, imágenes, textos o audio
                contenidos en el mismo. LA EMPRESA no garantiza el acceso y uso
                continuado o interrumpido de su sitio. La Aplicación puede
                eventualmente no estar disponible debido a dificultades técnicas
                o fallas de internet, o por cualquier otra circunstancia ajena a
                LA EMPRESA; en tales casos se procurará restablecer con la mayor
                celeridad posible sin que por ello pueda imputársele algún tipo
                de responsabilidad. LA EMPRESA no será responsable por ningún
                error u omisión contenidos en su aplicación 10) DATOS
                PERSONALES: El Usuario acepta y consiente libre y expresamente
                que los Datos Personales facilitados voluntariamente a LA
                EMPRESA a través de la Aplicación, se incluirán en una base de
                datos que quedará bajo responsabilidad de LA EMPRESA. Mediante
                la aceptación de los presentes Términos y condiciones los
                Médicos, profesionales de la Salud o Instituciones de la Salud,
                declaran aceptar, entender, y reconocer que sus datos se
                guardarán en la Base de Datos, a fin de mantener un Registro de
                los Profesionales Activos que utilicen la Aplicación. Asimismo,
                al aceptar los presentes Términos y condiciones, el Médico
                declara, acepta, entiende, y reconoce que sus datos personales,
                e información adicional, ingresados a la Base de Datos, podrán
                ser utilizados por LA EMPRESA a los fines de un mejor
                funcionamiento de la Aplicación, todo lo cual el Usuario
                autoriza a la aplicación web y móvil Canadoctors. LA EMPRESA
                está comprometida con la protección de la privacidad de la
                información personal de los Usuarios, conforme con las
                disposiciones por las leyes nacionales sobre Datos Personales en
                el país donde estén registrados los usuarios para el uso de la
                plataforma, adoptando para ello los recaudos técnicos y
                organizativos necesarios en lo que respecta a la custodia,
                almacenamiento, conservación y confidencialidad de los Datos
                Personales, con el fin de evitar su alteración, pérdida,
                tratamiento o acceso no autorizado. Debido a que ninguna
                transmisión vía Internet puede garantizar su íntegra seguridad,
                LA EMPRESA asegura a través de su Plataforma Digital
                Canadoctors, que los Datos Personales trasmitidos a través de
                internet se encuentren completamente seguros, asumiendo los
                Médicos, profesionales de la Salud o Instituciones de la Salud,
                la responsabilidad por la veracidad y confidencialidad de los
                mismos por lo que aceptan los presentes Términos y condiciones.
                En tal sentido, LA EMPRESA manifiesta que todos los datos
                ingresados por los Médicos, profesionales de la Salud,
                dispensarios o Instituciones de la Salud, serán tratados con
                confidencialidad, seguridad, y secreto profesional, en
                observancia a lo dispuesto según leyes. LA EMPRESA no se hace
                responsable de manipulación de datos, si el usuario ha
                compartido de manera irresponsable su clave, cayendo de esta
                manera la responsabilidad únicamente y exclusivamente en el
                usuario. El Usuario tendrá la facultad de ejercer el derecho de
                acceso (rectificar, actualizar y/o suprimir) a sus Datos
                personales ingresados, en forma gratuita con intervalos no
                inferiores a seis meses. Los Datos Personales de los Médicos
                suscriptos a la presente serán guardados durante todo el tiempo
                durante el cual se encuentren activos en el uso de la
                Aplicación, y hasta transcurridos un máximo de 5 años luego de
                comunicada la baja de la Cuenta, sin importar cuál sea la razón
                de la baja mencionada. 11) COMPROMISO DE PROTECCIÓN DE DATOS Y
                RECONOCIMIENTO DE RESPONSABILIDAD DEL MÉDICO: Mediante la
                aceptación de los presentes Términos y condiciones los Médicos,
                profesionales de la Salud o Instituciones de la Salud, se
                comprometen a proteger con su mayor esfuerzo y diligencia todos
                los datos a los cuales accedieren en virtud del uso de la
                aplicación, ya sean suyos personales, y/o de los historiales de
                consultas a los que acceda, y/o de los Usuarios, y/o cualquier
                otro dato al que accedan en virtud del uso de la Aplicación.
                Asimismo los Médicos, profesionales de la Salud o Instituciones
                de la Salud, declaran aceptar, entender, y reconocer que son los
                únicos responsables de la protección de todos los datos que
                ingresen al dispositivo que utilice para acceder a la
                Aplicación. En virtud de ello declaran y reconocen que serán
                responsables por cualquier daño que pueda ocasionarse por el mal
                uso de la información que ingrese a su dispositivo, ya sea de su
                parte o de algún tercero, liberando a LA EMPRESA y/o sus
                dependientes de toda responsabilidad en este sentido. 12) ÁMBITO
                TERRITORIAL DE USO DE LA APLICACIÓN: El uso de la presente
                aplicación se encuentra estrictamente limitado al territorio en
                el cual los Médicos, profesionales de la Salud o Instituciones
                de la Salud, se encuentren habilitados para ejercer la medicina.
                En virtud de ello, mediante la aceptación de los presentes
                Términos y condiciones, los Médicos, profesionales de la Salud o
                Instituciones de la Salud, declaran entender que el uso de la
                presente aplicación se encuentra estrictamente limitado al
                territorio donde se hallaren inscriptos. Mediante la aceptación
                de los presentes Términos y condiciones, los Médicos,
                profesionales de la Salud o Instituciones de la Salud, se
                comprometen a abstenerse de utilizar la Aplicación en caso de
                encontrarse fuera del territorio donde se encuentren
                registrados. 13) CONSULTAS GRATUITAS y OFERTAS: El Médico no
                cobrará a LA EMPRESA honorario alguno por las consultas
                atendidas respecto de promociones de salud. En caso que LA
                EMPRESA habilite la Aplicación para la prestación de servicios
                adicionales por parte de los Profesionales de la Salud en el
                futuro, éstos se podrán implementar con cargo, a exclusiva
                voluntad y conformidad de la EMPRESA. Al aceptar los Términos y
                condiciones de Canadoctors autorizan a LA EMPRESA a que pueda
                publicar ofertas de descuentos en consultas tanto en médicos
                privados como clínicas, con el fin de tener mayor acogida entre
                sus usuarios. Declaro haber leído y entendido los términos y
                condiciones precedentemente descritos por lo que manifiesto mi
                voluntad de modo expreso.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermAndCondition;
