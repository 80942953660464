import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  EMAIL,
  PASSWORD,
  NAME,
  PHONE,
  LASTNAME,
} from "../../validations/SignUP";
import { usePasswordToggle } from "../../hooks/usePasswordToggle";
import LogoSabia from "../../assets/images/logos/sabia/sabiaColors.svg";
import { Link } from "react-router-dom";
import FormField from "../../components/FormFiel/FormFiel";
import { getUsersEmails } from "../../services/getUsersEmails";
import { useEffect, useState } from "react";

type UserSubmitForm = {
  firstName: string;
  lastName: string;
  country: string;
  countryCode: string;
  email: string;
  password: string;
  phone: string;
  confirmPassword: string;
  termConditions: string;
  reference: number;
  active: boolean;
  doctor: boolean;
  medicalRecordComplete: boolean;
  datos: any;
};

function SignUpSabia() {
  const [passwordInputType, toggleIcon] = usePasswordToggle();
  const [usersEmails, setUsersEmails] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserSubmitForm>();

  const password = watch("password");
  const email = watch("email");

  useEffect(() => {
    const getUsersData = async () => {
      await getUsersEmails(email).then((res) => {
        setUsersEmails(res);
      });
    };
    getUsersData();
  }, [email]);

  const onSubmit: SubmitHandler<UserSubmitForm> = (data) => {
    let datos = {
      firstName: data.firstName,
      lastName: data.lastName,
      country: data.country,
      email: data.email,
      phone: data.countryCode + data.phone,
      password: data.password,
      active: true,
      doctor: false,
      medicalRecordComplete: false,
      reference: 4,
    };

    fetch("https://canadoctors.herokuapp.com/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: datos }),
    }).then((response) => response.json());
    try {
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 min-h-screen flex justify-center items-center">
      <div className="absolute w-60 h-60 rounded-xl bg-white -top-5 -left-16 z-0 transform rotate-45 hidden md:block"></div>
      <div className="absolute w-48 h-48 rounded-xl bg-white bottom-6 right-6 transform rotate-12 hidden md:block"></div>
      <div className="py-12 px-14 m-4 bg-white rounded-2xl shadow-xl max-w-3xl w-full">
        <div className="w-full flex justify-center">
          <img src={LogoSabia} alt="logo" width={300} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-2">
            <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="xl:w-1/2">
                <FormField
                  id="firstName"
                  name="nombre"
                  required={true}
                  type="text"
                  maxLength={25}
                  minLength={3}
                  placeholder="Nombre"
                  errors={errors}
                  register={register}
                  validation={NAME}
                />
              </div>

              <div className="xl:w-1/2">
                <FormField
                  id="lastName"
                  name="apellido"
                  required={true}
                  type="text"
                  maxLength={20}
                  minLength={3}
                  placeholder="Apellido"
                  errors={errors}
                  register={register}
                  validation={LASTNAME}
                />
              </div>
            </div>

            <FormField
              id="country"
              name="país"
              required={true}
              type="select"
              errors={errors}
              register={register}
              options={[{ value: "PR", label: "🇵🇷 Puerto Rico" }]}
            />

            <div className="sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="flex-grow xl:w-1/4 xl:pr-2">
                <FormField
                  id="countryCode"
                  name="codigo país"
                  required={true}
                  type="select"
                  errors={errors}
                  register={register}
                  options={[
                    { value: "", label: "Código País" },
                    { value: "+54", label: "🇦🇷 Argentina +54" },
                    { value: "+1", label: "🇵🇷 Puerto Rico +1" },
                  ]}
                />
              </div>

              <div className="flex-grow">
                <FormField
                  id="phone"
                  name="telefono"
                  required={true}
                  type="number"
                  maxLength={10}
                  minLength={8}
                  placeholder="Teléfono"
                  errors={errors}
                  register={register}
                  validation={PHONE}
                />
              </div>
            </div>

            <FormField
              id="email"
              required={true}
              type="email"
              maxLength={35}
              minLength={6}
              placeholder="Correo Electrónico"
              errors={errors}
              validation={EMAIL}
              register={register}
              {...register("email", {
                validate: () =>
                  usersEmails === undefined || "El correo ya esta registrado.",
              })}
              name="email"
            />
            <div className="gap-4 sm:flex-col md:flex-row lg:flex-row xl:flex sm:space-y-0 space-y-2">
              <div className="flex flex-col relative xl:w-1/2">
                <FormField
                  id="password"
                  name="contraseña"
                  type={passwordInputType as any}
                  required={true}
                  minLength={8}
                  maxLength={16}
                  placeholder="Contraseña"
                  errors={errors}
                  register={register}
                  validation={PASSWORD}
                  toggleIcon={toggleIcon}
                />
              </div>

              <div className="flex flex-col relative xl:w-1/2">
                <FormField
                  id="confirmPassword"
                  type={passwordInputType as any}
                  minLength={8}
                  required={true}
                  maxLength={16}
                  placeholder="Confirmar Contraseña"
                  errors={errors}
                  register={register}
                  validation={PASSWORD}
                  toggleIcon={toggleIcon}
                  {...register("confirmPassword", {
                    validate: (value) =>
                      value === password || "Las contraseñas no coinciden",
                  })}
                  name="confirmar contraseña"
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-gray-400 text-sm mt-2">
                * La contraseña debe tener al menos 8 caracteres, una mayúscula,
                una minúscula y un número.
              </p>
            </div>

            <FormField
              id="termConditions"
              label="He leído y comprendido los Terminos y Condiciones."
              name="terminos y condiciones"
              type="checkbox"
              required={true}
              errors={errors}
              errorCheckbox="Debes aceptar los terminos y condiciones"
              register={register}
              toLink="/terms"
            />

            <div className="space-y-6">
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center bg-[#00963f]  hover:bg-[#8CBB93] text-gray-100 p-3  rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
                >
                  Registrarse
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="text-center mt-6">
          <p className="mt-4 text-sm">
            ¿Ya tienes una cuenta?{" "}
            <Link to="/" className="text-sm text-[#5FBAA8] hover:opacity-75">
              Iniciar Sesión
            </Link>
          </p>
        </div>
      </div>
      <div className="w-40 h-40 absolute bg-white rounded-full top-2 right-2 hidden md:block"></div>
      <div className="w-20 h-40 absolute bg-white rounded-full bottom-20 left-10 transform rotate-45 hidden md:block"></div>
    </div>
  );
}

export default SignUpSabia;
