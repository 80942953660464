import FormField from "../../../components/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

function Region({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        label="Tipo de licencia"
        id="typeOfLicense"
        name="tipo de licencia"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar tipo de licencia" },
          { value: "Nueva Licencia", label: "Nueva Licencia" },
          { value: "Renovación de Licencia", label: "Renovación de Licencia" },
          { value: "Turista", label: "Turista" },
        ]}
      />

      <FormField
        label="Lugar de recogido"
        id="location"
        name="lugar de recogido"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar lugar de recogido" },
          { value: "1", label: "FarmaVerde" },
          { value: "2", label: "Foresta" },
          { value: "3", label: "Leafwell" },
          { value: "4", label: "Turf" },
          { value: "5", label: "Wecan" },
          { value: "6", label: "VidaCann" },
          { value: "7", label: "Rocky Mountain Cannabis" },
          { value: "8", label: "Clinica Verde" },
          { value: "9", label: "Farm Assists" },
          { value: "10", label: "Frontiers Humacao" },
          { value: "11", label: "Bwell" },
          { value: "12", label: "THC PR" },
          { value: "13", label: "FARO Dispensary" },
          { value: "14", label: "Green Growers" },
          { value: "15", label: "B&B Medical Boutique" },
          { value: "16", label: "Simply Green Farmacy" },
          { value: "17", label: "SINSEMILLA" },
          { value: "18", label: "Frontiers Caguas" },
          { value: "19", label: "Big Papa's Okie Toke" },
        ]}
      />
    </>
  );
}

export default Region;
