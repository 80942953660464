import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
interface ModalProps {
  title: string;
  contents: React.ReactNode;
}

function Modal({ title, contents }: ModalProps) {
  const [showModal, setShowModal] = useState(false);

  const handleOnClose = (e: any) => {
    if (e.target.id === "modal") setShowModal(false);
  };

  return (
    <div>
      <button
        className="py-4 px-5 bg-white hover:opacity-70 text-cdgreen transition ease-in duration-200 text-center text-base font-bold shadow-md focus:outline-none rounded-lg"
        type="button"
        onClick={() => setShowModal(true)}
      >
        {title}
      </button>

      {showModal && (
        <div
          onClick={handleOnClose}
          className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center"
        >
          <div className="relative w-full my-6 mx-auto max-w-3xl p-4">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col justify-center w-full bg-white outline-none focus:outline-none overflow-auto">
              <div className="flex justify-end p-2">
                <button
                  className="bg-transparent border-0 text-cdgreen self-start"
                  onClick={() => setShowModal(false)}
                >
                  <FaTimes className="text-xl hover:text-cdgreenlight" />
                </button>
              </div>
              <div className="max-h-[80vh]">{contents}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
