import { saveImg } from "../application/saveImg";

const saveTakeCareOfYourself = async (dataUser, dataRecord, imgData) => {
  const uploadedImageURLs = await saveImg(imgData, dataRecord.socialSecurity);

  const dataUserr = {
    ...dataUser,
    urlIdImg: uploadedImageURLs[2],
    urlProfileImg: uploadedImageURLs[1],
  };

  const userDataResponse = await fetch(
    "https://canadoctors.herokuapp.com/auth/register",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: dataUserr }),
    }
  );

  if (!userDataResponse.ok) {
    throw new Error("Failed to insert user data.");
  }

  const userData = await userDataResponse.json();
  const userId = userData.id;

  const datosMedicalRecord = {
    ...dataRecord,
    patientId: userId,
  };

  // Insert medical record data
  const medicalRecordResponse = await fetch(
    "https://canadoctors.herokuapp.com/medics/create",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
      body: JSON.stringify({ data: datosMedicalRecord }),
    }
  );

  if (!medicalRecordResponse.ok) {
    throw new Error("Failed to insert medical record data.");
  }

  return userId;
};

export default saveTakeCareOfYourself;
