import FormField from "../../FormFiel/FormFiel";
import { LICNUM } from "../../../validations/medicalRecordPr";

type PropsForm = {
  register: any;
  errors: any;
};

const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const numericValue = e.target.value.replace(/\D/g, "");
  let formattedValue = "";
  if (numericValue.length > 0) {
    formattedValue += numericValue.substr(0, 3);
  }
  if (numericValue.length > 3) {
    formattedValue += "-" + numericValue.substr(3, 2);
  }
  if (numericValue.length > 5) {
    formattedValue += "-" + numericValue.substr(5);
  }

  e.target.value = formattedValue;
};

function PersonalInformationPr({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        id="adult"
        label="¿Eres mayor de 21 años?"
        name="adulto"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      <FormField
        label="Género"
        id="gender"
        required={true}
        name="género"
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccionar género" },
          { value: "F", label: "Femenino" },
          { value: "M", label: "Masculino" },
        ]}
      />

      <FormField
        label="Fecha de nacimiento"
        id="birthDate"
        required={true}
        name="fecha de nacimiento"
        type="date"
        errors={errors}
        register={register}
      />

      <FormField
        label="Número de seguro social"
        id="socialSecurity"
        required={true}
        name="número de seguro social"
        type="text"
        maxLength={11}
        minLength={11}
        onChange={handleSSNChange}
        placeholder="Número de seguro social"
        errors={errors}
        register={register}
        //validation={LICNUM}
      />
    </>
  );
}

export default PersonalInformationPr;
