import * as CryptoJS from "crypto-js";

const evertecAuth = async (idTrasaction, dataUser, amount) => {
  const { firstName, lastName, email, phone } = dataUser;
  try {
    const login = process.env.REACT_APP_EVERTEC_LOGIN;
    const secretKey = process.env.REACT_APP_EVERTEC_SECRET;

    const seed = new Date().toISOString();
    const rawNonce = Math.floor(Math.random() * 1000000);

    // Generar tranKey usando SHA-256 con CryptoJS
    const dataToHash = rawNonce + seed + secretKey;
    const tranKey = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Base64);

    const nonce = btoa(rawNonce.toString()); // Convertir a base64

    // Obtén la fecha y hora actual
    const now = new Date();

    // Suma 30 minutos a la fecha y hora actual
    const expirationDate = new Date(now.getTime() + 30 * 60000);

    // Formatea la nueva fecha de expiración según el formato ISO8601
    const formattedExpiration = expirationDate.toISOString();

    const url = "https://checkout.placetopay.com/";
    //const urlTest = "https://checkout-test.placetopay.com/api/session"

    const requestData = {
      locale: "es_CO",
      auth: {
        login: login,
        tranKey: tranKey,
        nonce: nonce,
        seed: seed,
      },
      buyer: {
        name: firstName,
        surname: lastName,
        email: email,
        mobile: phone,
      },
      payment: {
        reference: idTrasaction,
        description: "Cuida de ti",
        amount: {
          currency: "USD",
          total: amount,
        },
      },
      expiration: formattedExpiration,
      skipResult: true,
      returnUrl: "http://localhost:3000/administrador/pacientes",
      ipAddress: "127.0.0.1",
      userAgent: "PlacetoPay Sandbox",
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    if (response.ok) {
      const responseData = await response.json();
      return responseData;
    } else {
      throw new Error(
        `Error en la solicitud: ${response.status} - ${response.statusText}`
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export default evertecAuth;
