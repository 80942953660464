import Message from "../../components/Message/Message";
import UserCard from "../../components/UserCard/UserCard";
import { useAuthContext } from "../../contexts/authContext";
import Modal from "../../components/Modal/Modal";
import UserCharacteristics from "../../components/UserCard/UserCharacteristics.tsx";
import MedicalRecord from "../../components/MedicalRecord/MedicalRecord";
import SelectPlan from "../../components/SelectService/SelectService";

import logoCanadoctors from "../../assets/images/logos/canadoctors/equilibramelogo.svg";
import logoPlanteo from "../../assets/images/logos/elPlanteo/elPlanteoColors.svg";
import logoStrain from "../../assets/images/logos/strain/strainColors.svg";
import logoSabia from "../../assets/images/logos/sabia/sabiaColors2.svg";
import logoFM from "../../assets/images/logos/FM/FM_bg_Blue.png";
import logoTetra from "../../assets/images/logos/Tetra/tetraLogo.svg";
import AlertsMenssages from "../../components/AlertsMenssages";
import referenceNames from "../../components/data/referenceNames";
import TotalCounter from "../../components/TotalCounter";
import { getPatientsByReferenceAndStatus } from "../../services/getDispensary";
import TotalDispensary from "../../components/TotalDispensary";

function Home() {
  const { user } = useAuthContext();

  const referencesTypes = () => {
    switch (user.reference) {
      case 1:
        return logoCanadoctors;
      case 2:
        return logoStrain;
      case 3:
        return logoPlanteo;
      case 4:
        return logoSabia;
      case 5:
        return logoFM;
      case 6:
        return logoCanadoctors;
      default:
        break;
    }
  };

  function handleUrlClick() {
    switch (user.reference) {
      case 1:
        return window.open("https://canadoctors.com/", "_blank");
      case 2:
        return window.open("https://thestrainapp.com", "_blank");
      case 3:
        return window.open("https://elplanteo.com/", "_blank");
      case 4:
        return window.open("https://sabiacollective.com/", "_blank");
      case 5:
        return window.open("https://firstmedicalcannabis.com/", "_blank");
      case 6:
        return window.open("https://tetrapr.com/", "_blank");
      default:
        break;
    }
  }

  if (user.rol === 1) {
    return (
      <div className="mt-2 w-full lg:px-6">
        {user.medicalRecordComplete ? (
          <div>
            <Message
              message={"Realizar Solicitud"}
              button={
                <Modal title={"Realizar Solicitud"} contents={<SelectPlan />} />
              }
            />

            <div className="flex flex-col lg:flex-row justify-between mt-4 lg:gap-4">
              <UserCard state="AUTORIZADO" />
              <UserCharacteristics />
            </div>
          </div>
        ) : (
          <div>
            <Message
              message={"Debes completar Tu Historial Médico"}
              button={
                <Modal
                  title={"Completar Perfil"}
                  contents={<MedicalRecord />}
                />
              }
            />
            <div className="flex flex-col lg:flex-row justify-between mt-4 lg:gap-4">
              <UserCard state="NO AUTORIZADO" />
              <UserCharacteristics />
            </div>
          </div>
        )}

        <button className="w-full sm:py-0 py-2" onClick={handleUrlClick}>
          <div className="container px-5 py-20 mx-auto flex items-center lg:flex-row flex-col bg-white rounded-xl shadow-xl">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
            {/* }  <h2 className="text-xs text-cdgreen tracking-widest font-medium title-font mb-1 uppercase">
                {referenceNames[user.reference] || "No definido"}
              </h2>
            */}
              <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900">
                Usuario Proveniente Del Sitio
              </h1>
            </div>
            <div className="flex md:ml-auto lg:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
              <img
                src={referencesTypes()}
                alt="canadoctors"
                className="w-96 h-40 object-contain"
              />
            </div>
          </div>
        </button>
      </div>
    );
  }

  if (user.rol === 2) {
    return (
      <div className="mt-2 w-full lg:px-6">
        <div>
          <Message
            message={"Completar Perfil"}
            button={
              <Modal title={"Completar Perfil"} contents={<MedicalRecord />} />
            }
          />
        </div>
      </div>
    );
  }

  if (user.rol === 3) {
    return (
      <>
        <div className="pb-4">
          <TotalDispensary />
        </div>

        <button className="w-full sm:py-0 py-2" onClick={handleUrlClick}>
          <div className="container px-5 py-20 mx-auto flex items-center lg:flex-row flex-col bg-white rounded-xl shadow-xl">
            <div className="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
              <h2 className="text-xs text-cdgreen tracking-widest font-medium title-font mb-1 uppercase">
                {referenceNames[user.reference] || "No definido"}
              </h2>
              <h1 className="md:text-3xl text-2xl font-medium title-font text-gray-900">
                Bienvenido al panel de administración
              </h1>
            </div>
            <div className="flex md:ml-auto lg:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
              <img
                src={referencesTypes()}
                alt="canadoctors"
                className="w-96 h-40 object-contain"
              />
            </div>
          </div>
        </button>
      </>
    );
  }

  if (user.rol === 4) {
    return <TotalCounter />;
  }

  return (
    <div className="mt-2 w-full lg:px-6">
      <AlertsMenssages type="danger" message={"Usuario no autorizado"} />
    </div>
  );
}

export default Home;
