import FormField from "../../FormFiel/FormFiel";
import Modal from "../../Modal";
import {
  CIUDAD,
  DIRECCION,
  NROREPROCANN,
  OBRASOCIAL,
} from "../../../validations/medicalRecordAr";

type PropsForm = {
  register: any;
  errors: any;
};

function PersonalCharacteristicsAr({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        label="Ciudad"
        id="city"
        required={true}
        name="ciudad"
        type="text"
        placeholder="Ciudad"
        errors={errors}
        register={register}
        //validation={CIUDAD}
      />

      <FormField
        label="Dirección"
        id="address"
        required={true}
        name="dirección"
        type="text"
        placeholder="Dirección"
        errors={errors}
        register={register}
        //validation={DIRECCION}
      />

      <div className="flex-grow">
        <FormField
          label="Obra Social"
          id="socialWork"
          name="obra social"
          required={true}
          type="text"
          placeholder="Obra Social"
          errors={errors}
          register={register}
          //validation={OBRASOCIAL}
        />
      </div>

      <FormField
        id="reprocannId"
        name="codigo de reprocann"
        label="Código de Vinculación REPROCANN"
        required={false}
        type="text"
        maxLength={13}
        placeholder="Código de Vinculacion"
        errors={errors}
        register={register}
        //validation={NROREPROCANN}
      />
      <div className="mt-2 justify-end flex">
        <Modal
          title={"¿Cómo obtener el código?"}
          contents={
            <div className="w-full flex justify-center items-center h-[50vh]">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/DjsZEZnV9l8"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          }
        />
      </div>
    </>
  );
}

export default PersonalCharacteristicsAr;
