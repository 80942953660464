import React, { useState, useEffect } from "react";
import FormField from "../../../components/FormFiel";
import { getSpecialPatients } from "../../../services/getSpecialPatients";

type PropsForm = {
  register: any;
  errors: any;
  employee: boolean;
  onEmployeeIdChange: (employeeId: string) => void;
  onEmployeeIdExistence: (exists: boolean) => void;
};

function Coupon({
  register,
  errors,
  employee,
  onEmployeeIdChange,
  onEmployeeIdExistence,
}: PropsForm) {
  const checkEmployeeId = async (employeeId: any) => {
    try {
      const res = await getSpecialPatients(employeeId);

      if (res && res.redeem === false) {
        onEmployeeIdExistence(true);
      }
    } catch (error) {
      console.error("Error checking special employee:", error);
      // Handle error as needed
    }
  };

  const handleEmployeeIdChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newEmployeeId = e.target.value;

    if (newEmployeeId.length >= 3) {
      await checkEmployeeId(newEmployeeId);
    } else {
      onEmployeeIdExistence(false);
    }
    onEmployeeIdChange(newEmployeeId);
  };

  return (
    <div className=" -mt-4">
      <FormField
        id="coupon"
        name="cupon"
        type="text"
        placeholder="Cupón de descuento"
        autoComplete="off"
        errors={errors}
        register={register}
      />

      {employee && (
        <FormField
          id="IdEmployee"
          name="ID empleado"
          type="text"
          placeholder="ID de empleado"
          autoComplete="off"
          errors={errors}
          register={register}
          onChange={handleEmployeeIdChange}
        />
      )}
    </div>
  );
}

export default Coupon;
