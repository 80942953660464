const baseURL = "https://canadoctors.herokuapp.com";

const getPatientsLastId = async () => {
  try {
    // Obtener todos los usuarios de la tabla "users"
    const response = await fetch(`${baseURL}/users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }

    const data = await response.json();

    return data[data.length - 1].id;
  } catch (error) {
    console.error("Error fetching patients:", error);
    return null;
  }
};

const getPatients = async () => {
  const response = await fetch(`${baseURL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.json();
  const filterPatients = data.filter((user) => user.rol === 1);
  return filterPatients;
};

const getPatientsId = async (id) => {
  const response = await fetch(`${baseURL}/users/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
  });
  const data = await response.json();
  return data;
};

const getPatientss = async () => {
  const response = await fetch(`${baseURL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const patientData = await response.json();

  // Realizar la consulta individual por cada paciente para obtener las transacciones correspondientes
  const joinedData = await Promise.all(
    patientData.map(async (patient) => {
      const transactionResponse = await fetch(
        `${baseURL}/transactions/patient/${patient.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const transactionData = await transactionResponse.json();
      return {
        ...patient,
        transactions: transactionData,
      };
    })
  );
  // Filtrar solo los usuarios que tienen transacciones
  const usersWithTransactions = joinedData.filter(
    (user) => user.transactions.length > 0
  );

  return usersWithTransactions;
};

export { getPatientsLastId, getPatients, getPatientsId, getPatientss };
