const baseURL = "https://canadoctors.herokuapp.com/transactions/patient";

const getPaypalPayments = async (id) => {
  const response = await fetch(`${baseURL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  try {
    const data = await response.json();
    return data;
  } catch (err) {
    //console.log(err.message);
  }
};

export { getPaypalPayments };
