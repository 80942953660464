import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const TransactionStatus = ({ data }) => {
  const xAxisStyle = {
    fontSize: "12px", // Tamaño de fuente
    fontWeight: "bold", // Peso de la fuente
    color: "#333", // Color del texto
    // Otros estilos que desees aplicar
  };

  const xAxisWidth = 100; // Ancho máximo del eje X antes de envolver el texto

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="status"
            tick={{
              style: xAxisStyle,
              width: xAxisWidth,
              height: "auto",
              wordWrap: "break-word",
            }}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#00a099">
            <LabelList dataKey="status" position="top" content={() => null} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TransactionStatus;
