import React, { useEffect, useState, ChangeEvent } from "react";
import { FaFileImage, FaTrash } from "react-icons/fa";
import Webcam from "react-webcam";
import AlertsMenssages from "../AlertsMenssages";

interface ProfilePictureProps {
  onImageChange: (image: File | null) => void;
  perfil?: boolean;
  inputId: string;
}

enum ImageSource {
  UPLOAD = "UPLOAD",
  CAMERA = "CAMERA",
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  onImageChange,
  perfil,
  inputId,
}) => {
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [source, setSource] = useState<ImageSource>(ImageSource.UPLOAD);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [hasCamera, setHasCamera] = useState<boolean>(false);
  const webcamRef = React.useRef<Webcam>(null);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasVideoInput = devices.some(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(hasVideoInput);
      } catch (error) {
        console.error("Error checking camera availability:", error);
        setHasCamera(false);
      }
    };

    checkCameraAvailability();
  }, []);

  const convertToJPEG = (inputFile: File) => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        const img = new Image();
        img.src = base64String;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0, img.width, img.height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const jpegFile = new File([blob], "captura.jpeg", {
                  type: "image/jpeg",
                });
                resolve(jpegFile);
              } else {
                reject("Error converting to JPEG");
              }
            },
            "image/jpeg",
            1.0
          );
        };
      };
      reader.readAsDataURL(inputFile);
    });
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      try {
        const jpegFile = await convertToJPEG(file);
        setImage(jpegFile);
        onImageChange(jpegFile);
      } catch (error) {
        console.error("Error converting image to JPEG:", error);
      }
    }
  };

  // Manejador de eventos para arrastrar sobre el componente
  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  // Manejador de eventos para soltar sobre el componente
  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const file = e.dataTransfer?.files[0];
    if (file) {
      setImage(file);
      onImageChange(file);
    }
  };

  const handleCapture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      // Convertir la URL de datos a un Blob
      fetch(imageSrc as string)
        .then((response) => response.blob())
        .then(async (blob) => {
          try {
            // Convertir el Blob a un archivo JPEG
            const jpegFile = await convertToJPEG(
              new File([blob], "captura.jpeg", { type: "image/jpeg" })
            );

            // Almacenar la imagen capturada en el estado
            setCapturedImage(imageSrc);
            setImage(jpegFile);
            onImageChange(jpegFile);
          } catch (error) {
            console.error("Error al convertir la imagen:", error);
          }
        })
        .catch((error) => {
          console.error("Error al convertir la imagen:", error);
        });
    }
  };

  const handleImageClick = () => {
    if (source === ImageSource.CAMERA && capturedImage) {
      // Si estamos en la vista de cámara y hay una imagen capturada,
      // al hacer clic sobre ella, reiniciamos los estados relacionados
      // con la imagen capturada para mostrar la cámara nuevamente.
      setCapturedImage(null);
      setImage(null);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center font-sans">
      <label
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        htmlFor={inputId}
        className={`${
          perfil
            ? "cursor-pointer w-80 h-80 flex flex-col items-center justify-center rounded-full border-2 border-dashed border-cdgreen bg-white text-center"
            : "mx-auto cursor-pointer flex w-full max-w-lg h-72 flex-col items-center justify-center rounded-xl border-2 border-dashed hover:opacity-80 border-cdgreen bg-white text-center"
        }`}
      >
        {source === ImageSource.UPLOAD ? (
          <>
            {preview ? (
              <div
                className={`${
                  perfil
                    ? "group relative w-80 h-80 object-contain rounded-full overflow-hidden"
                    : "group w-full rounded-xl relative object-fill overflow-hidden"
                }`}
              >
                <img
                  className="w-full h-full object-cover hover:opacity-40"
                  src={preview}
                  alt="preview"
                />
                <div className="hidden group-hover:flex justify-center items-center absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
                  <FaTrash
                    className="text-white text-6xl"
                    onClick={() => {
                      setImage(null);
                      onImageChange(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="flex w-full flex-col items-center p-6">
                <FaFileImage className="text-4xl text-cdgreen" />
                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
                  {perfil ? "Cargar Imagen de Perfil" : "Cargar Imagen"}
                </h2>
                <p className="mt-2 text-gray-500 tracking-wide">
                  Cargar o arrastrar su imagen aquí
                </p>
              </div>
            )}
            <input
              id={inputId}
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileChange}
            />
          </>
        ) : (
          <div className="h-full w-full">
            <div
              className={`${
                perfil
                  ? "group relative w-80 h-80 object-contain rounded-full overflow-hidden"
                  : "group relative h-full object-contain overflow-hidden"
              }`}
              onClick={handleImageClick} // Añadimos el manejador de clic a la imagen capturada
            >
              {capturedImage ? (
                <>
                  <img
                    className="w-full h-full object-cover hover:opacity-40"
                    src={capturedImage}
                    alt="captured"
                    onClick={() => {
                      setImage(null);
                      onImageChange(null);
                    }}
                  />
                  <div className="hidden group-hover:flex justify-center items-center absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
                    <FaTrash
                      className="text-white text-6xl"
                      onClick={() => {
                        setImage(null);
                        onImageChange(null);
                      }}
                    />
                  </div>
                </>
              ) : (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="w-full h-full object-cover"
                />
              )}
            </div>
            {capturedImage ? null : (
              <button
                className="mt-2 border-[#00A099] text-black border rounded py-4 px-8 w-full hover:text-[#00A099]"
                onClick={handleCapture}
              >
                Tomar foto desde la cámara
              </button>
            )}
          </div>
        )}
      </label>

      {image === null && hasCamera === true && (
        <div className="mt-20 flex flex-col">
          <button
            className="bg-cdgreen text-white rounded py-4 px-8 w-full"
            onClick={() =>
              setSource(
                source === ImageSource.CAMERA
                  ? ImageSource.UPLOAD
                  : ImageSource.CAMERA
              )
            }
          >
            {source === ImageSource.CAMERA ? "Subir una foto" : "Abrir camara"}
          </button>
        </div>
      )}
      <div className="mt-4">
        {hasCamera === false && (
          <AlertsMenssages
            type="danger"
            message="No se detecto camara, solo puede cargar una foto de perfil"
          />
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
