import React from "react";

function Faq() {
  return (
    <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-5xl mt-5 tracking-tight">FAQ</h2>
        <p className="text-neutral-500 text-xl mt-3">
          Preguntas frecuentes sobre pagos electronicos.
        </p>
      </div>
      <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> ¿Qué es Placetopay?</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Placetopay es la plataforma de pagos electrónicos que usa (INCLUIR
              NOMBRE DEL COMERCIO) para procesar en línea las transacciones
              generadas en la tienda virtual con las formas de pago habilitadas
              para tal fin.
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> ¿Cómo puedo pagar?</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              En la tienda virtual de (INCLUIR NOMBRE DEL COMERCIO) usted podrá
              realizar su pago con los medios habilitados para tal fin. Usted,
              de acuerdo a las opciones de pago escogidas por el comercio, podrá
              pagar a través (Definir los medios de pago habilitados Visa,
              MasterCard, ATH Móvil).
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>
                ¿Es seguro ingresar mis datos de mis tarjetas en este sitio web?
              </span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Para proteger tus datos (INCLUIR NOMBRE DEL COMERCIO) delega en
              Placetopay la captura de la información sensible. Nuestra
              plataforma de pagos cumple con los más altos estándares exigidos
              por la norma internacional PCI DSS de seguridad en transacciones
              con tarjeta de crédito, débito y prepago. Además tiene certificado
              de seguridad SSL expedido por GeoTrust una compañía Verisign, el
              cual garantiza comunicaciones seguras mediante la encriptación de
              todos los datos hacia y desde el sitio; de esta manera, te podrás
              sentir seguro a la hora de ingresar la información de su tarjeta.
              Durante el proceso de pago, en el navegador se muestra el nombre
              de la organización autenticada, la autoridad que lo certifica y la
              barra de dirección cambia a color verde. Estas características son
              visibles de inmediato y dan garantía y confianza para completar la
              transacción en Placetopay. PREGUNTAS FRECUENTES SOBRE PAGOS
              ELECTRÓNICOSUSO CONFIDENCIAL 4www.evertecinc.com Placetopay
              también cuenta con el monitoreo constante de McAfee Secure y la
              firma de mensajes electrónicos con Certicámara.
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>
                ¿Puedo realizar el pago cualquier día y a cualquier hora?
              </span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Sí, en (INCLUIR NOMBRE DEL COMERCIO) podrás realizar tus compras
              en línea los 7 días de la semana, las 24 horas del día a sólo un
              clic de distancia.
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> ¿Puedo cambiar la forma de pago?</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Si aún no has finalizado tu pago, podrás volver al paso inicial y
              elegir la forma de pago que prefieras. Una vez finalizada la
              compra no es posible cambiar la forma de pago.
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>
                {" "}
                ¿Pagar electrónicamente tiene algún valor para mí como
                comprador?
              </span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              No, los pagos electrónicos realizados a través de Placetopay no
              generan costos adicionales para el comprador.
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> ¿Qué debo hacer si mi transacción no concluyó?</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              En primera instancia deberás revisar si llegó un mail de
              confirmación del pago en tu cuenta de correo electrónico (la
              inscrita en el momento de realizar el pago), en caso de no haberlo
              recibido, deberás contactar a (INCLUIR EL CORREO ELECTRÓNICO DEL
              COMERCIO) para confirmar el estado de la transacción. En caso que
              tu transacción haya declinado, debes verificar si la información
              de la cuenta es válida, está habilitada para compras no
              presenciales y si tienes cupo o saldo disponible. Si después de
              esto continua con la declinación debes comunicarte con (INCLUIR
              NOMBRE DEL COMERCIO).
            </p>
          </details>
        </div>
        <div className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> ¿Qué debo hacer si no recibí el comprobante de pago?</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shapeRendering="geometricPrecision"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Por cada transacción aprobada a través de Placetopay, recibirás un
              comprobante del pago con la referencia de compra en la dirección
              de correo electrónico que indicaste al momento de pagar. Si no lo
              recibes, podrás contactar a la línea (INCLUIR EL TELÉFONO DEL
              COMERCIO) o al correo electrónico (INCLUIR EL CORREO ELECTRÓNICO
              DEL COMERCIO), para solicitar el reenvío del comprobante a la
              misma dirección de correo
            </p>
          </details>
        </div>
      </div>
    </div>
  );
}

export default Faq;
