import {
  CANTIDAD,
  CP,
  DIRECCION,
  MEDICAMENTOS,
} from "../../../validations/medicalRecordPr";
import FormField from "../../../components/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

function MedicalInformationPr({ register, errors }: PropsForm) {
  return (
    <>
      <FormField
        label="Dirección fisica"
        id="address"
        required={true}
        name="dirección"
        type="text"
        placeholder="Dirección fisica"
        errors={errors}
        register={register}
        //validation={DIRECCION}
      />

      <FormField
        label="Código postal"
        id="zipCode"
        required={true}
        name="código postal"
        type="number"
        minLength={4}
        placeholder="Código postal"
        errors={errors}
        register={register}
        //validation={CP}
      />

      <FormField
        label="Ciudad"
        id="city"
        name="ciudad"
        required={true}
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Seleccione una ciudad" },
          { value: "34", label: "Adjuntas" },
          { value: "26", label: "Aguada" },
          { value: "01", label: "Aguadilla" },
          { value: "45", label: "Aguas Buenas" },
          { value: "68", label: "Aibonito" },
          { value: "28", label: "Añasco" },
          { value: "06", label: "Arecibo" },
          { value: "72", label: "Arroyo" },
          { value: "07", label: "Barceloneta" },
          { value: "41", label: "Barranquitas" },
          { value: "15", label: "Bayamón" },
          { value: "55", label: "Cabo Rojo" },
          { value: "46", label: "Caguas" },
          { value: "04", label: "Camuy" },
          { value: "80", label: "Canóvanas" },
          { value: "20", label: "Carolina" },
          { value: "14", label: "Cataño" },
          { value: "70", label: "Cayey" },
          { value: "53", label: "Ceiba" },
          { value: "37", label: "Ciales" },
          { value: "44", label: "Cidra" },
          { value: "66", label: "Coamo" },
          { value: "43", label: "Comerío" },
          { value: "40", label: "Corozal" },
          { value: "77", label: "Culebra" },
          { value: "11", label: "Dorado" },
          { value: "24", label: "Fajardo" },
          { value: "81", label: "Florida" },
          { value: "59", label: "Guánica" },
          { value: "71", label: "Guayama" },
          { value: "61", label: "Guayanilla" },
          { value: "16", label: "Guaynabo" },
          { value: "47", label: "Gurabo" },
          { value: "05", label: "Hatillo" },
          { value: "54", label: "Hormigueros" },
          { value: "51", label: "Humacao" },
          { value: "02", label: "Isabela" },
          { value: "36", label: "Jayuya" },
          { value: "64", label: "Juana Díaz" },
          { value: "49", label: "Juncos" },
          { value: "57", label: "Lajas" },
          { value: "33", label: "Lares" },
          { value: "31", label: "Las Marías" },
          { value: "50", label: "Las Piedras" },
          { value: "21", label: "Loíza" },
          { value: "23", label: "Luquillo" },
          { value: "08", label: "Manatí" },
          { value: "32", label: "Maricao" },
          { value: "74", label: "Maunabo" },
          { value: "29", label: "Mayagüez" },
          { value: "27", label: "Moca" },
          { value: "38", label: "Morovis" },
          { value: "52", label: "Naguabo" },
          { value: "42", label: "Naranjito" },
          { value: "39", label: "Orocovis" },
          { value: "73", label: "Patillas" },
          { value: "62", label: "Peñuelas" },
          { value: "63", label: "Ponce" },
          { value: "03", label: "Quebradillas" },
          { value: "25", label: "Rincón" },
          { value: "22", label: "Río Grande" },
          { value: "58", label: "Sabana Grande" },
          { value: "69", label: "Salinas" },
          { value: "56", label: "San Germán" },
          { value: "79", label: "San Juan" },
          { value: "48", label: "San Lorenzo" },
          { value: "30", label: "San Sebastián" },
          { value: "67", label: "Santa Isabel" },
          { value: "12", label: "Toa Alta" },
          { value: "13", label: "Toa Baja" },
          { value: "19", label: "Trujillo Alto" },
          { value: "35", label: "Utuado" },
          { value: "10", label: "Vega Alta" },
          { value: "09", label: "Vega Baja" },
          { value: "76", label: "Vieques" },
          { value: "65", label: "Villalba" },
          { value: "75", label: "Yabucoa" },
          { value: "60", label: "Yauco" },
        ]}
      />
    </>
  );
}

export default MedicalInformationPr;
