const baseURL = "https://canadoctors.herokuapp.com/users";
const baseURLMedical = "https://canadoctors.herokuapp.com/medics/";

const getPatientMedical = async (email) => {
  try {
    // Obtener información del usuario
    const response = await fetch(baseURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    const userData = await response.json();

    // Buscar al usuario por su correo electrónico
    const user = userData.find((user) => user.email === email);

    if (!user) {
      console.error("Usuario no encontrado");
      return null;
    }

    // Obtener información médica del usuario
    const responseMedical = await fetch(`${baseURLMedical}/${user.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
      },
    });

    const dataMedical = await responseMedical.json();

    // Devolver la información médica y del paciente en un objeto
    return {
      patientId: dataMedical.patientId,
      socialSecurity: dataMedical.socialSecurity,
    };
  } catch (error) {
    console.error("Error al obtener usuario y registro médico:", error);
    throw error; // Puedes manejar el error según tus necesidades
  }
};

export { getPatientMedical };
