import React, { useState } from "react";
import { useForm } from "react-hook-form";
import LogoCanadoctors from "../../assets/images/logos/canadoctors/canadoctorsColors.svg";
import FormField from "../../components/FormFiel/FormFiel";
import { getStatusTransaction } from "../../services/getStatusTransaction";
import referenceStatus from "../../components/data/referenceStatus";
import AlertsMenssages from "../../components/AlertsMenssages";
import referenceDispensaryLocation from "../../components/data/referenceDispensaryLocation";
import dataStatus from "./dataStatus";
import { FaClipboardCheck } from "react-icons/fa";

type Inputs = {
  id: number;
};

type Status = {
  type: string;
  status: number;
  dispensaryLocation: number;
};

function Status() {
  const [status, setStatus] = useState<Status | null>(null);
  const [queryPerformed, setQueryPerformed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = async (data: Inputs) => {
    setIsLoading(true);
    try {
      const res = await getStatusTransaction(data.id);
      setStatus(res);
      setQueryPerformed(true);
    } catch (error) {
      setStatus(null);
      setQueryPerformed(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConsultarNuevamente = () => {
    setStatus(null);
    setQueryPerformed(false);
    reset();
  };

  return (
    <div className="bg-gradient-to-b from-[#00A099]/80 to-[#8CBB93]/90 bg-opacity-40 min-h-screen flex justify-center items-center">
      <div className="max-w-2xl w-full p-6 sm:px-14 lg:px-8 px-2 m-4 bg-white rounded-2xl shadow-xl flex flex-col justify-center">
        <div className="pb-10 flex justify-center">
          <img src={LogoCanadoctors} alt="logo" width={300} />
        </div>

        {queryPerformed ? (
          status &&
          (Array.isArray(status)
            ? status.length > 0
            : Object.keys(status).length > 0) ? (
            <div>
              <div
                className={`w-full rounded-lg shadow-sm p-5 border-dashed border flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-0 mb-4 
                ${
                  (status.status < 5 && "bg-yellow-100 border-yellow-700") ||
                  ((status.status === 5 || status.status === 7) &&
                    "bg-cdgreenlight/30 border-green-700") ||
                  (status.status === 6 && "bg-red-100 border-red-700")
                }`}
              >
                <div className="flex flex-col sm:flex-row justify-start items-center gap-4">
                  <div className="bg-cdgreenlight flex p-2 rounded-md">
                    <FaClipboardCheck className="text-white w-6 h-6" />
                  </div>
                  <div className="text-center sm:text-left">
                    <h1 className="text-gray-700 font-bold tracking-wider">
                      Estado de su {status.type}
                    </h1>
                    <p className="text-gray-500 font-semibold">
                      Su licencia se encuentra en estado:{" "}
                      {referenceStatus[status.status]}
                    </p>
                    {status.status === 5 && (
                      <p className="text-gray-500 font-semibold">
                        Retirar su licencia en:{" "}
                        {referenceDispensaryLocation[status.dispensaryLocation]}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-12 md:col-span-6 lg:col-span-4 md:order-1 grid gap-4 xl:gap-6 border rounded-lg mb-4">
                <div className="relative overflow-hidden w-full h-full rounded-xl">
                  <div className="p-6 flex bg-white flex-col justify-between text-center rounded-xl dark:border-gray-700">
                    <div>
                      <h3 className="text-lg md:text-xl font-semibold text-gray-800">
                        Estado de su {status.type}
                      </h3>
                    </div>

                    <div className="mt-6">
                      <ul className="flex flex-col text-left">
                        {dataStatus.map((item) => (
                          <li
                            key={item.status}
                            className={`relative flex gap-x-4  overflow-hidden ${
                              item.status != 7 && "pb-7"
                            }`}
                          >
                            <div className="mt-0.5 relative h-full">
                              <div
                                className={`absolute top-7 bottom-0 ${
                                  item.status != 7 && "left-2.5"
                                } w-px h-96 -ml-px border-r border-dashed ${
                                  status.status > item.status &&
                                  "border-green-500"
                                } `}
                              ></div>
                              <item.icon
                                className={`text-gray-600 w-5 h-5 ${
                                  status.status >= item.status && "text-cdgreen"
                                }`}
                              />
                            </div>
                            <p
                              className={`py-1.5 px-2.5 rounded-full text-xs font-medium text-gray-600 bg-white border shadow-sm ${
                                status.status === item.status &&
                                " border-cdgreen border-8"
                              }}`}
                            >
                              <span className="font-semibold text-gray-800">
                                {item.title}
                              </span>
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="absolute top-1/2 -left-1/2 -z-[1] w-60 h-32 bg-purple-200 blur-[100px] -translate-y-1/2 dark:bg-violet-900/30"></div>
                </div>
              </div>

              <button
                onClick={() => handleConsultarNuevamente()}
                className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
              >
                Realizar una nueva consulta
              </button>
            </div>
          ) : (
            <div>
              <div className="mb-4">
                <AlertsMenssages
                  type="danger"
                  message="No se encontró ningún registro con el número de trámite ingresado."
                />
              </div>
              <button
                onClick={() => handleConsultarNuevamente()}
                className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
              >
                Realizar una nueva consulta
              </button>
            </div>
          )
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-2">
              <FormField
                id="id"
                name="número de trámite"
                required={true}
                type="text"
                maxLength={8}
                placeholder="Número de trámite"
                autoComplete="off"
                errors={errors}
                register={register}
              />
            </div>
            <div className="text-center mt-6">
              {isLoading ? (
                <button
                  type="submit"
                  className="w-full flex justify-center bg-cdgreen  hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
                >
                  <div className="w-full flex justify-center">
                    <div className="border-t-transparent border-solid animate-spin rounded-full border-white border-4 h-7 w-7"></div>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center bg-cdgreen  hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
                >
                  Consultar
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default Status;
