import FormField from "../../../components/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
};

function ShiftCalendar({ register, errors }: PropsForm) {
  return (
    <div className="flex flex-col">
      <FormField
        label="Por la mañana (8am-12pm)"
        id="question"
        required={true}
        name="pregunta1"
        value="1"
        type="radio"
        errors={errors}
        register={register}
      />
      <FormField
        label="Por la tarde (12pm-16-pm)"
        id="question"
        required={true}
        name="pregunta1"
        value="2"
        type="radio"
        errors={errors}
        register={register}
      />
      <FormField
        label="Por la noche (16-pm-20pm)"
        id="question"
        required={true}
        name="pregunta1"
        value="3"
        type="radio"
        errors={errors}
        register={register}
      />
    </div>
  );
}

export default ShiftCalendar;
