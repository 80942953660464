const baseURL = "https://canadoctors.herokuapp.com/transactions";
const baseURLUser = "https://canadoctors.herokuapp.com/users";

//muestra todas las trasacciones con los datos del usuario
const getTransactions = async () => {
  const [transactionResponse, patientResponse] = await Promise.all([
    fetch(`${baseURL}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
    fetch(`${baseURLUser}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  ]);

  try {
    const [transactionData, userData] = await Promise.all([
      transactionResponse.json(),
      patientResponse.json(),
    ]);

    const userMap = new Map(userData.map((user) => [user.id, user]));

    const transactionsWithPatientName = transactionData.map((transaction) => {
      const user = userMap.get(transaction.patientId);
      return {
        ...transaction,
        name: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
      };
    });

    return transactionsWithPatientName;
  } catch (err) {
    // Handle any JSON parsing errors here
  }
};

//muestra todas las trasacciones con los datos del usuario por referencia
const getTransactionsReference = async (reference) => {
  const transactionResponse = await fetch(`${baseURL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  const patientResponse = await fetch(`${baseURLUser}`, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  try {
    const transactionData = await transactionResponse.json();

    const userData = await patientResponse.json();
    const patientsByReference = userData.filter(
      (user) => user.rol === 1 && user.reference === reference
    );

    const transactionsWithPatientInfo = transactionData
      .filter((transaction) =>
        patientsByReference.find((user) => user.id === transaction.patientId)
      )
      .map((transaction) => {
        const associatedUser = patientsByReference.find(
          (user) => user.id === transaction.patientId
        );

        return {
          ...transaction,
          name: associatedUser.firstName,
          lastName: associatedUser.lastName,
          email: associatedUser.email,
          phone: associatedUser.phone,
        };
      });

    return transactionsWithPatientInfo;
  } catch (err) {
    // Handle any JSON parsing errors here
  }
};

export { getTransactions, getTransactionsReference };
