import {
  CANTIDAD,
  CP,
  DIRECCION,
  MEDICAMENTOS,
} from "../../../validations/medicalRecordPr";
import FormField from "../../FormFiel/FormFiel";

type PropsForm = {
  register: any;
  errors: any;
  watch?: any;
};

function MedicalInformationPr({ register, errors, watch }: PropsForm) {
  const useMedicines = watch("useMedicines");
  const haveAllergies = watch("haveAllergies");
  const smoke = watch("smoke");
  const drink = watch("drink");

  return (
    <>
      <FormField
        label="Dirección"
        id="address"
        required={true}
        name="dirección"
        type="text"
        placeholder="Dirección"
        errors={errors}
        register={register}
        //validation={DIRECCION}
      />

      <FormField
        label="Código postal"
        id="zipCode"
        required={true}
        name="código postal"
        type="number"
        minLength={4}
        placeholder="Código postal"
        errors={errors}
        register={register}
        //validation={CP}
      />

      <FormField
        label="¿Está tomando algún medicamento?"
        id="useMedicines"
        name="medicamento"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      {useMedicines && (
        <FormField
          label="Nombre del medicamento"
          id="medicines"
          required={true}
          name="nombre del medicamento"
          type="text"
          maxLength={30}
          minLength={3}
          placeholder="Ingrese el nombre del medicamento"
          errors={errors}
          register={register}
          //validation={MEDICAMENTOS}
        />
      )}

      <FormField
        label="¿Padece de alguna alergia?"
        id="haveAllergies"
        name="alergia"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      {haveAllergies && (
        <FormField
          label="Tipo de alergia"
          id="allergies"
          required={true}
          name="tipo de alergia"
          type="text"
          maxLength={25}
          minLength={3}
          placeholder="Ingrese el tipo de alergia"
          errors={errors}
          register={register}
          //validation={MEDICAMENTOS}
        />
      )}

      <FormField
        label="¿Fuma cigarrillos?"
        id="smoke"
        name="cigarrillos"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      {smoke && (
        <FormField
          label="¿Cuántos cigarrillos fuma al día?"
          id="frequenceSmoke"
          required={true}
          name="cantidad de cigarrillos"
          type="number"
          minLength={1}
          maxLength={3}
          placeholder="Ingrese la cantidad de cigarrillos"
          errors={errors}
          register={register}
          //validation={CANTIDAD}
        />
      )}

      <FormField
        label="¿Bebe alcohol?"
        id="drink"
        name="alcohol"
        type="checkbox"
        required={false}
        errors={errors}
        register={register}
      />

      {drink && (
        <>
          <FormField
            label="¿Cada cuánto bebe alcohol?"
            id="frecuenceDrink"
            name="frecuencia de alcohol"
            required={true}
            type="select"
            errors={errors}
            register={register}
            options={[
              { value: "", label: "Seleccionar" },
              { value: "Diariamente", label: "Diariamente" },
              { value: "Semanal", label: "Semanal" },
              { value: "Mensual", label: "Mensual" },
            ]}
          />
          <div className="font-bold h-6 mt-3 text-black text-xs uppercase">
            Tipo de alcohol
          </div>
          <div className="flex justify-between">
            <FormField
              label="whisky/ron/vodka"
              id="whisky"
              name="whisky/ron/vodka"
              type="checkbox"
              required={false}
              errors={errors}
              register={register}
            />
            <FormField
              label="cerveza"
              id="cerveza"
              name="cerveza"
              type="checkbox"
              required={false}
              errors={errors}
              register={register}
            />
            <FormField
              label="vino"
              id="vino"
              name="vino"
              type="checkbox"
              required={false}
              errors={errors}
              register={register}
            />
          </div>
        </>
      )}
    </>
  );
}

export default MedicalInformationPr;
