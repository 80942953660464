import React from "react";

interface ActionCardProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick: () => void;
}

function ActionCard(props: ActionCardProps) {
  const { title, description, icon, onClick } = props;

  return (
    <button className="m-4" onClick={onClick}>
      <div className="shadow-lg rounded-2xl bg-white w-64 h-64 hover:bg-slate-50">
        <div className="w-full h-full text-center flex items-center justify-center">
          <div className="flex flex-col">
            <div className="bg-cdgreen w-20 h-20 rounded-full flex items-center justify-center m-auto">
              <div className="text-5xl text-white">{icon}</div>
            </div>
            <p className="text-gray-800 text-lg font-bold mt-4">{title}</p>
            <p className="text-gray-600 text-xs py-2 px-6">{description}</p>
          </div>
        </div>
      </div>
    </button>
  );
}

export default ActionCard;
