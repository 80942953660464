import React, { useEffect, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { saveImg } from "../../application/saveImg";
import { on } from "events";

type ChekcoutPProps = {
  idSocialSecurity: string | null;
  typeLicence: string;
  price: any;
  transactionData: any;
  imgData: any;
  userId: any;
  onPaymentComplete: (value: boolean) => void;
};

function ChekcoutP({
  typeLicence,
  price,
  transactionData,
  imgData,
  idSocialSecurity,
  userId,
  onPaymentComplete,
}: ChekcoutPProps) {
  const [idSSN, setIdSSN] = useState(idSocialSecurity);
  const [paymentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    setIdSSN(idSocialSecurity);
  }, [idSocialSecurity]);

  const initialOptions = {
    "client-id":
      "AcuPvAhjpm-WJfsBaZMDnjJN497XemM7Mf6TUlFKP4fOaQnXI1-5-8jGJtGe7Kgen_sTXFEjlzl69Kt0",
    //"AaeeuL5jSwHKG7x3Fq__6u_pXeZkdouxTOdCRcQe2VJb8JlwCZrYKbquOlQQAi0mLG4LvxrUT2JulEP_",

    locale: "en_US",
  };

  const handlePaymentApprove = async (data: any, actions: any) => {
    try {
      const orderDetails = await actions.order.capture();

      const uploadedImageURLs = await saveImg(imgData, idSSN);

      const datosTransaction = {
        ...transactionData,
        patientId: userId,
        amount: price,
        method: "PayPal",
        state: orderDetails.status,
        date: orderDetails.create_time,
        orderId: orderDetails.id,
        status: 1,
        urlVoucherImg: uploadedImageURLs[0],
      };

      // Insert transaction data
      const transactionResponse = await fetch(
        "https://canadoctors.herokuapp.com/transactions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          },
          body: JSON.stringify({ data: datosTransaction }),
        }
      );

      if (!transactionResponse.ok) {
        throw new Error("Failed to insert transaction data.");
      }

      const name = orderDetails.payer.name?.given_name;
      alert(`Transacción completada por ${name}`);

      setPaymentCompleted(true); // Indica que el pago y la lógica están completos
      onPaymentComplete(true); // Indica que el pago está completo
    } catch (error) {
      console.error("Ocurrió un error:", error);
    }
  };

  const handleClosePayPal = () => {
    // Cerrar la ventana emergente de PayPal manualmente
    setPaymentCompleted(true);
  };

  return (
    <div className="mt-4 relative flex-1 flex-col justify-center">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
          <p className="text-black font-semibold capitalize text-lg">
            Video consulta médica
          </p>
          <p className="text-black text-md mt-5">
            Acceso a plataforma Canadoctors, consulta con médico autorizado por
            la junta de cannabis medicinal.
          </p>
          <p className="text-black text-lg mt-5 font-bold">{typeLicence}</p>
          <span className="text-lg font-extrabold mt-5">Total: ${price}</span>
        </div>
      </div>
      <div className="mt-8">
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: price,
                    },
                  },
                ],
              });
            }}
            onApprove={handlePaymentApprove}
            onCancel={handleClosePayPal}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  );
}

export default ChekcoutP;
