import React, { useState } from "react";
import { Document, Page, StyleSheet, PDFViewer } from "@react-pdf/renderer";

interface PDFViewerProps {
  pdfUrl: string; // Propiedad para la URL del PDF
  buttonText: string; // Propiedad para el texto del botón
}

const styles = StyleSheet.create({
  page: {
    size: "A4",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
});

const PDFViewerComponent: React.FC<PDFViewerProps> = ({
  pdfUrl,
  buttonText,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <button
        className="w-full flex justify-center bg-cdgreen hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold cursor-pointer transition ease-in duration-500"
        onClick={() => {
          setLoading(true);
          window.open(pdfUrl, "_blank"); // Abre la URL del PDF en una nueva ventana
        }}
      >
        {loading ? "Abriendo PDF..." : buttonText}
      </button>
    </div>
  );
};

export default PDFViewerComponent;
