import { useAuthContext } from "../../contexts/authContext";

function Profile() {
  const { user } = useAuthContext();

  const country = () => {
    switch (user.country) {
      case "AR":
        return "Argentina";
      case "PR":
        return "Puerto Rico";

      default:
        return "No definido";
    }
  };

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4">Mi Perfil</h1>
        </div>
        <div className="z-0 flex flex-col min-w-0 break-words sm:bg-white w-full mt-6 sm:shadow-xl rounded-lg">
          <div className="mb-10 px-6">
            <div className="flex flex-wrap justify-center m-6 -mt-10">
              <div className="w-screen lg:w-[650px] px-4 lg:order-2 flex justify-center">
                <div className="flex justify-center items-center w-40 h-40 rounded-full shadow-2xl bg-cdgreen">
                  <span className="text-8xl font-bold text-white uppercase">
                    {user.firstName[0] + user.lastName[0]}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-center mt-6">
              <h3 className="text-4xl font-semibold leading-normal mb-2 capitalize">
                {`${user.firstName} ${user.lastName}`}
              </h3>
              <div className="text-lg leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                {country()}
              </div>
              <div className="mb-2 mt-4">{user.email}</div>
              <div className="mb-2">{user.phone}</div>
              <div className="mb-2">
                Historial Médico{" "}
                {user.medicalRecordComplete ? (
                  <span className="px-4 py-1 text-base rounded-full text-green-600 bg-green-200">
                    Completo
                  </span>
                ) : (
                  <span className="px-4 py-1 text-base rounded-full text-red-600 bg-red-200">
                    Incompleto
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
