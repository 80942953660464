const referenceDispensaryLocation: Record<number, string> = {
    279:  "Tetra Ave. 65 Infanteria" ,
    108:  "Tetra Bayamón" ,
    278:  "Tetra Cesar Gonzalez" ,
    265:  "Tetra Condado" ,
    281:  "Tetra Dorado" ,
    264:  "Tetra Escorial" ,
    430:  "Tetra Fajardo" ,
    220:  "Tetra Guaynabo" ,
    110:  "Tetra Hato Rey" ,
    242:  "Tetra Humacao" ,
    429:  "Tetra Isla Verde" ,
    224:  "Tetra Lomas Verdes" ,
    221:  "Tetra Manati" ,
    433:  "Tetra Ponce" ,
    //186:  "Tetra Roosevelt" ,
    //187:  "Tetra San Patricio" ,
    219:  "Tetra Trujillo Alto" ,
    222:  "Tetra Vega Baja" ,
    6300: "Tetra Añasco",
}
  
  export default referenceDispensaryLocation;

