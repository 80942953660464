const baseURL = "https://canadoctors.herokuapp.com/transactions";

const updateStatusPayment = async (id, status) => {
  const response = await fetch(`${baseURL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ data: { status: parseInt(status) } }),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(`Update request failed: ${errorMessage}`);
  }

  try {
    const data = await response.json();
    return data;
  } catch (err) {
    // Handle any JSON parsing errors here
  }
};

export { updateStatusPayment };
