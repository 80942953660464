import FormField from "../../FormFiel/FormFiel";
import { MEDIDAS, PESO, PROFESION } from "../../../validations/medicalRecordPr";

type PropsForm = {
  register: any;
  errors: any;
};

const formatWeight = (value: any) => {
  const numericValue = value.replace(/\D/g, "");
  const integerPart = numericValue.substr(0, 3);
  const decimalPart = numericValue.substr(3, 1);
  let formattedValue = integerPart;
  if (decimalPart) {
    formattedValue += `.${decimalPart}`;
  }

  return formattedValue;
};

function PersonalCharacteristicsPr({ register, errors }: PropsForm) {
  const handleWeightChange = (e: any) => {
    const formattedValue = formatWeight(e.target.value);
    e.target.value = formattedValue;
  };
  return (
    <>
      <FormField
        label="Peso en lb"
        id="weight"
        required={true}
        name="peso"
        type="text"
        maxLength={5}
        minLength={2}
        onChange={handleWeightChange}
        placeholder="Por ejemplo: 65.8"
        errors={errors}
        register={register}
        //validation={PESO}
      />

      <div className="mt-2">
        <h2 className="w-full">Altura:</h2>
        <div className="flex justify-between gap-2">
          <div className="w-full">
            <FormField
              label="Pies"
              required={true}
              id="foots"
              name="pies"
              type="text"
              maxLength={1}
              minLength={1}
              placeholder="Pies"
              errors={errors}
              register={register}
              //validation={MEDIDAS}
            />
          </div>

          <div className="w-full">
            <FormField
              label="Pulgadas"
              id="inches"
              name="pulgadas"
              required={true}
              type="text"
              maxLength={2}
              minLength={1}
              placeholder="Pulgadas"
              errors={errors}
              register={register}
              //validation={MEDIDAS}
            />
          </div>
        </div>
      </div>

      <FormField
        label="Seleccionar color de ojos"
        id="eyesColor"
        required={true}
        name="color de ojos"
        type="select"
        errors={errors}
        register={register}
        options={[
          { value: "", label: "Color de ojos" },
          { value: "azules", label: "azules" },
          { value: "castaños", label: "castaños" },
          { value: "grises", label: "grises" },
          { value: "miel", label: "miel" },
          { value: "negros", label: "negros" },
          { value: "verdes", label: "verdes" },
        ]}
      />

      <FormField
        label="Profesión"
        id="profession"
        required={true}
        name="profesión"
        type="text"
        placeholder="Profesión"
        minLength={3}
        maxLength={20}
        errors={errors}
        register={register}
        //validation={PROFESION}
      />
    </>
  );
}

export default PersonalCharacteristicsPr;
