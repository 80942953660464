const baseURL = "https://canadoctors.herokuapp.com/medics/";

const getMedicalRecordPr = async (id) => {
  const response = await fetch(`${baseURL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.json();
  return data;
};

export { getMedicalRecordPr };
