import { useEffect, useState } from "react";
import {
  FaCheckCircle,
  FaEdit,
  FaIdCard,
  FaLaptopMedical,
} from "react-icons/fa";
import AlertsMenssages from "../../components/AlertsMenssages";
import CheckoutAr from "../../components/Checkout/CheckoutAr";
import ChekcoutP from "../../components/Checkout/CheckoutP";
import { useAuthContext } from "../../contexts/authContext";

const servicesAR = [
  {
    id: 1,
    name: "Solicitar Reprocann",
    icon: <FaIdCard />,
    price: "15.000",
    description: "Solicitar una nueva licencia Reprocann",
    characteristics: [
      "Acceso a plataforma de Canadoctors ",
      "Consulta médica por videollamada de 5 a 20 min (el tiempo de videollamada dependerá de cada profesional médico)",
      "Cantidad de consultas: 1 (una)",
      "Gestión de certificado",
      "Validez del Reprocann: 1 (año).",
    ],
  },
  {
    id: 2,
    name: "Renovar Reprocann",
    icon: <FaEdit />,
    price: "15.000",
    description: "Solicitar renovación de licencia Reprocann",
    characteristics: [
      "Acceso a plataforma de Canadoctors",
      "Consulta médica por videollamada de 5 a 20 min (el tiempo de videollamada dependerá de cada profesional médico)",
      "Cantidad de consultas: 1 (una)",
      "Gestión de certificado",
      "Validez del Reprocann: 1 (año).",
    ],
  },
  {
    id: 3,
    name: "TeleMedicina",
    icon: <FaLaptopMedical />,
    price: "15.000",
    description: "Solicitar una video consulta",
    characteristics: [
      "Acceso a plataforma de Canadoctors ",
      "Consulta médica por videollamada de 5 a 20 min (el tiempo de videollamada dependerá de cada profesional médico)",
      "Cantidad de consultas: 1 (una)",
    ],
  },
];

const servicesPR = [
  {
    id: 1,
    name: "Nueva Licencia",
    icon: <FaIdCard />,
    description: "Solicitar una nueva licencia",
    characteristics: [
      "Acceso a plataforma de Canadoctors ",
      "Teleconsulta de 5 a 20 min (dependiendo del profesional médico)",
      "Cantidad de consultas: 1 (una)",
      "Gestión de licencia",
      "Validez de licencia apartir de la fecha de emisión: 1 año",
    ],
  },
  {
    id: 2,
    name: "Renovar Licencia",
    icon: <FaEdit />,
    description: "Solicitar renovación",
    characteristics: [
      "Acceso a plataforma de Canadoctors ",
      "Teleconsulta de 5 a 20 min (dependiendo del profesional médico)",
      "Cantidad de consultas: 1 (una)",
      "Gestión y renovación de licencia",
      "Validez de licencia apartir de la fecha de emisión: 1 año",
    ],
  },
  {
    id: 3,
    name: "TeleMedicina",
    icon: <FaLaptopMedical />,
    description: "Solicitar una video consulta",
    characteristics: [
      "Acceso a plataforma de Canadoctors ",
      "Teleconsulta de 5 a 20 min (dependiendo del profesional médico)",
      "Cantidad de consultas: 1 (una)",
    ],
  },
];

function Services() {
  const { user } = useAuthContext();
  const [handleClick, sethandleClick] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [user.medicalRecordComplete]);

  const dispensaryPrice = () => {
    switch (user.reference) {
      case 1:
        return "60";
      case 2:
        return "50";
      case 3:
        return "50";
      case 4:
        return "45";
      case 5:
        return "45";
      case 6:
        return "45";
      default:
        break;
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="text-center p-8">
          <h1 className="font-bold text-4xl text-black pb-4">Servicios</h1>
        </div>

        <div className="w-full flex justify-center pt-8">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-[#00A099]/90 border-8 h-20 w-20"></div>
        </div>
      </>
    );
  }

  if (user.medicalRecordComplete) {
    return (
      <div className="antialiased w-full text-gray-400 font-inter">
        <div className="container px-4 mx-auto">
          <div>
            <div className="text-center my-10">
              <h1 className="font-bold text-4xl text-black">Servicios</h1>
              <p className="text-light text-gray-500 text-xl mt-2">
                {!handleClick
                  ? " Selecciona el servicio que necesitas"
                  : "Realiza tu pago"}
              </p>
            </div>

            {!handleClick && (
              <>
                {user.country === "PR" && (
                  <div className="grid grid-cols-1 lg:grid-cols-3 justify-evenly gap-5 pt-5">
                    {servicesPR.map((service) => (
                      <div
                        key={service.id}
                        className="bg-white shadow-lg rounded-xl text-center overflow-hidden w-full my-2 hover:shadow-2xl hover:scale-105 transition duration-200 ease-in cursor-pointer"
                      >
                        <div className="w-full h-[120px] mx-1 py-5 border-b border-gray-400 flex flex-col justify-center items-center">
                          <h2 className="font-bold text-3xl lg:text-2xl xl:text-3xl text-black px-4">
                            {service.name}
                          </h2>
                          <h3 className="font-normal text-cdgreen text-2xl lg:text-xl xl:text-2xl mt-2">
                            ${dispensaryPrice()}
                          </h3>
                        </div>

                        <div className="my-5">
                          <div className="flex justify-center text-5xl lg:text-4xl xl:text-5xl text-cdgreen">
                            {service.icon}
                          </div>
                          <p className="text-gray-500 text-base pt-2">
                            {service.description}
                          </p>
                        </div>
                        <div className="leading-8 mb-10 text-sm lg:text-xs xl:text-sm font-light">
                          <ul className="h-[200px] mx-1">
                            {service.characteristics.map((item, index) => (
                              <li
                                key={index}
                                className="flex justify-start items-start gap-2 mb-3"
                              >
                                <div className="mt-1 ml-1">
                                  <FaCheckCircle className="text-cdgreen" />
                                </div>
                                <span className="text-left leading-normal">
                                  {item}
                                </span>
                              </li>
                            ))}
                          </ul>
                          <div className="w-full mt-10 px-6">
                            <button
                              key={service.id}
                              onClick={() => sethandleClick(service.name)}
                              className="w-full block bg-gray-400 text-white font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-cdgreen hover:text-white"
                            >
                              Solicitar
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {user.country === "AR" && (
                  <div className="grid grid-cols-1 lg:grid-cols-3 justify-evenly gap-5 pt-5">
                    {servicesAR.map((service) => (
                      <div
                        key={service.id}
                        className="bg-white shadow-lg rounded-xl text-center overflow-hidden w-full my-2 hover:shadow-2xl hover:scale-105 transition duration-200 ease-in cursor-pointer"
                      >
                        <div className="w-full h-[120px] mx-1 py-5 border-b border-gray-400 flex flex-col justify-center items-center">
                          <h2 className="font-bold text-3xl lg:text-2xl xl:text-3xl text-black px-4">
                            {service.name}
                          </h2>
                          <h3 className="font-normal text-cdgreen text-2xl lg:text-xl xl:text-2xl mt-2">
                            ${service.price}
                          </h3>
                        </div>

                        <div className="my-5">
                          <div className="flex justify-center text-5xl lg:text-4xl xl:text-5xl text-cdgreen">
                            {service.icon}
                          </div>
                          <p className="text-gray-500 text-base pt-2">
                            {service.description}
                          </p>
                        </div>
                        <div className="leading-8 mb-10 text-sm lg:text-xs xl:text-sm font-light">
                          <ul className="h-[180px] mx-1">
                            {service.characteristics.map((item, index) => (
                              <li
                                key={index}
                                className="flex justify-start items-start gap-2 mb-3"
                              >
                                <div className="mt-1 ml-1">
                                  <FaCheckCircle className="text-cdgreen" />
                                </div>

                                <span className="text-left leading-normal">
                                  {item}
                                </span>
                              </li>
                            ))}
                          </ul>
                          <div className="w-full mt-10 px-6">
                            <button
                              key={service.id}
                              onClick={() => sethandleClick(service.name)}
                              className="w-full block bg-gray-400 text-white font-medium text-xl py-4 rounded-xl hover:shadow-lg transition duration-200 ease-in-out hover:bg-cdgreen hover:text-white"
                            >
                              Solicitar
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            {user.country === "PR" && (
              <div className="flex justify-center">
                <div className="sm:w-3/4 w-full">
                  {handleClick && (
                    <ChekcoutP
                      typeLicence={handleClick}
                      price={dispensaryPrice()}
                    />
                  )}
                </div>
              </div>
            )}

            {user.country === "AR" && (
              <div className="flex justify-center">
                <div className="sm:w-3/4 w-full">
                  {handleClick && <CheckoutAr />}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="text-center p-8">
      <h1 className="font-bold text-4xl text-black pb-4">Servicios</h1>
      <AlertsMenssages
        type="warning"
        message="No completaste tú Historial Medico"
      />
    </div>
  );
}

export default Services;
