import FormField from "../../FormFiel/FormFiel";
import { MEDIDAS, PESO, PROFESION } from "../../../validations/medicalRecordPr";

type PropsForm = {
  register: any;
  errors: any;
};

function MedicalConditionPr({ register, errors }: PropsForm) {
  return (
    <FormField
      label="Condición Médica"
      id="condition"
      required={true}
      name="Condición Médica"
      type="textarea"
      rows={10}
      maxLength={500}
      placeholder="Escriba su condición médica"
      errors={errors}
      register={register}
      //validation={PESO}
    />
  );
}

export default MedicalConditionPr;
