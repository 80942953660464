import {
    FaClipboardCheck,
    FaCheckCircle,
    FaClinicMedical,
    FaUserClock,
    FaExclamationCircle,
    FaUserEdit,
    FaHospitalUser,
    FaHourglassHalf,
    FaPeopleCarry,
  } from "react-icons/fa";

const dataStatus = [
    {
      status: 0,
      icon: FaHourglassHalf,
      title: "Pendiente de aprobación de pago",
    },
    {
      status: 1,
      icon: FaUserClock,
      title: "Pendiente de cita medica",
    },
    {
      status: 2,
      icon: FaUserEdit,
      title: "Pendiente de recomendación médica",
    },
    {
      status: 3,
      icon: FaHospitalUser,
      title: "Recomendación médica finalizada",
    },
    {
      status: 4,
      icon: FaClinicMedical,
      title: "Pendiente en Depto. de salud",
    },
    {
      status: 5,
      icon: FaCheckCircle,
      title: "Aprobado",
    },
    {
      status: 6,
      icon: FaExclamationCircle,
      title: "En Revisión",
    },
    {
      status: 7,
      icon: FaPeopleCarry,
      title: "Entregado",
    },
  ];

  export default dataStatus;