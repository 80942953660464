import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useAuthContext } from "../../../contexts/authContext";
import Table from "../../../components/Table";
import { getPatients } from "../../../services/getPatients";
import { getDispensary } from "../../../services/getDispensary";
import referenceNames from "../../../components/data/referenceNames";
import AlertsMenssages from "../../../components/AlertsMenssages";
import LoadingComponent from "../../../components/LoadingComponent";
import TableExcel from "../../../components/TableExcel";

interface Patient {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  id: string;
  medicalRecordComplete: boolean;
}

function PatientsTable() {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [allPatients, setAllPatients] = useState<Patient[]>([]);

  useEffect(() => {
    setIsLoading(true);

    if (user.rol === 3) {
      getDispensary(user.reference).then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }

    if (user.rol === 4) {
      getPatients().then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }
  }, [user.rol]);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: (_row: unknown, index: number) => index + 1,
        Cell: ({ value }: { value: number }) => <span>{value}</span>,
      },
      {
        Header: "Nombre",
        accessor: "firstName",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Apellido",
        accessor: "lastName",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: { value: string }) => <span>{value}</span>,
      },

      {
        Header: "Pais",
        accessor: "country",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Referencia",
        accessor: "reference",
        Cell: ({ value }: { value: number }) => (
          <span className="capitalize">{referenceNames[value]}</span>
        ),
      },
      {
        Header: "Registro Medico",
        accessor: "medicalRecordComplete",
        Cell: ({ value }: { value: boolean }) => (
          <span className="capitalize">
            {value ? (
              <span className="px-3 py-1 text-base rounded-full text-green-600 bg-green-200 ">
                Completo
              </span>
            ) : (
              <span className="px-3 py-1 text-base rounded-full text-red-600 bg-red-200 ">
                Incompleto
              </span>
            )}
          </span>
        ),
      },
      {
        Header: "Ver +",
        accessor: "id",
        Cell: ({ value }: { value: string }) => (
          <div>
            <Link to={`ver/?id=${value}`}>
              <FaEye className="text-xl text-gray-700 hover:opacity-75" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (allPatients.length === 0) {
    return (
      <AlertsMenssages type="warning" message="No hay registros de pacientes" />
    );
  }

  return (
    <>
      {user.rol === 4 && (
        <TableExcel
          data={allPatients}
          filename="Tabla de pacientes"
          sheetName="pacientes"
        />
      )}

      <Table
        columns={columns}
        data={allPatients}
        title={"Lista de pacientes"}
      />
    </>
  );
}

export default PatientsTable;
