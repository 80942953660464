import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../../contexts/authContext";
import { useState, useEffect } from "react";
import Panel from "../Panel/Panel";

interface PrivateRouteProps {
  allowedRoles: number[];
}

function PrivateRoute({ allowedRoles }: PrivateRouteProps) {
  const { isAuthenticated, user } = useAuthContext();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    // Este efecto se ejecutará cuando cambie isAuthenticated o user.role
    setAuthChecked(true);
  }, [isAuthenticated, user.role]);

  // Espera hasta que la autenticación se haya verificado
  if (!authChecked) {
    return null; // o algún indicador de carga si es necesario
  }

  if (!isAuthenticated || !allowedRoles.includes(user.rol)) {
    return <Navigate to="/" />;
  }

  return (
    <Panel>
      <Outlet />
    </Panel>
  );
}

export default PrivateRoute;
