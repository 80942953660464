const baseURL = "https://canadoctors.herokuapp.com";

const getDispensary = async (reference) => {
  const response = await fetch(`${baseURL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.json();
  const filterPatients = data.filter(
    (user) => user.rol === 1 && user.reference === reference
  );
  return filterPatients;
};

const getDispensaryTotals = async () => {
  const response = await fetch(`${baseURL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.json();

  // Crear un objeto para almacenar los totales por dispensario
  const dispensaryTotals = {};

  // Filtrar y contar los usuarios por dispensario
  data.forEach((user) => {
    if (user.rol === 1) {
      const reference = user.reference;
      if (dispensaryTotals[reference]) {
        dispensaryTotals[reference]++;
      } else {
        dispensaryTotals[reference] = 1;
      }
    }
  });

  return dispensaryTotals;
};

// totales de cada region de tetra
const getDispensaryLocation = async () => {
  const response = await fetch(`${baseURL}/transactions/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const data = await response.json();

  // Crear un objeto para almacenar los totales por dispensario
  const dispensaryLocation = {};

  // Filtrar y contar los usuarios por dispensario
  data.forEach((transaction) => {
    if (transaction.dispensaryLocation !== 0) {
      const location = transaction.dispensaryLocation;
      if (dispensaryLocation[location]) {
        dispensaryLocation[location]++;
      } else {
        dispensaryLocation[location] = 1;
      }
    }
  });

  return dispensaryLocation;
};

const getPatientsByReferenceAndStatus = async (reference) => {
  // Obtener la lista de pacientes por referencia desde la tabla de usuarios
  const response = await fetch(`${baseURL}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const userData = await response.json();

  // Filtrar los pacientes que tienen la referencia deseada
  const patientsByReference = userData.filter(
    (user) => user.rol === 1 && user.reference === reference
  );

  // Obtener las transacciones de los pacientes con la referencia deseada
  const patientIds = patientsByReference.map((patient) => patient.id);
  const transactionResponse = await fetch(`${baseURL}/transactions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  const transactionData = await transactionResponse.json();

  // Filtrar las transacciones de los pacientes por sus IDs
  const transactionsForPatients = transactionData.filter((transaction) =>
    patientIds.includes(transaction.patientId)
  );

  // Contar la cantidad de pacientes por estado (status)
  const patientsByStatus = {};
  transactionsForPatients.forEach((transaction) => {
    const status = transaction.status;
    if (patientsByStatus[status]) {
      patientsByStatus[status]++;
    } else {
      patientsByStatus[status] = 1;
    }
  });

  return patientsByStatus;
};

export {
  getDispensary,
  getDispensaryTotals,
  getPatientsByReferenceAndStatus,
  getDispensaryLocation,
};
