import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface TableExcelProps {
  data: any[]; // Cambia 'any' por el tipo de datos que esperas
  filename: string;
  sheetName: string;
}

const TableExcel: React.FC<TableExcelProps> = ({
  data,
  filename,
  sheetName,
}) => {
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelData = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(excelData, `${filename}.xlsx`);
  };

  return (
    <button
      className="bg-cdgreen p-2 ml-4 rounded-lg text-white font-bold hover:opacity-75"
      onClick={exportToExcel}
    >
      Descargar Excel
    </button>
  );
};

export default TableExcel;
