import { on } from "events";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const styleInput =
  "block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-[#00A099] focus:outline-none focus:ring-2 focus:ring-[#00A099]";

const styleSelect =
  "block w-full px-3 py-2.5 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-[#00A099] focus:outline-none focus:ring-2 focus:ring-[#00A099]";

const styleCheckbox = "form-checkbox h-5 w-5 accent-[#00A099]";

interface FormFieldProps {
  id: string;
  name: string;
  type:
    | "text"
    | "email"
    | "number"
    | "password"
    | "date"
    | "select"
    | "checkbox"
    | "radio"
    | "textarea";
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  value?: string;
  errors: any;
  register: any;
  validation?: any;
  toggleIcon?: any;
  options?: any;
  label?: string;
  required?: boolean;
  valueAsNumber?: boolean;
  errorCheckbox?: string;
  toLink?: any;
  warning?: string;
  rows?: number;
  onChange?: any;
  autoComplete?: string;
}

const FormField: FunctionComponent<FormFieldProps> = function (props) {
  const {
    id,
    name,
    type,
    minLength,
    maxLength,
    placeholder,
    errors,
    register,
    validation,
    toggleIcon,
    value,
    options,
    label,
    required,
    valueAsNumber,
    errorCheckbox,
    toLink,
    warning,
    rows,
    onChange,
    autoComplete,
  } = props;

  function renderField() {
    switch (type) {
      case "text":
      case "email":
      case "number":
      case "date":
      case "password":
        return (
          <div>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <input
              id={id}
              name={name}
              type={type}
              value={value}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              autoComplete={autoComplete}
              className={`${styleInput}
                ${errors[id] ? "border-red-500 border-2" : "border-gray-300"} `}
              {...register(id, {
                onChange: onChange,
                valueAsNumber: valueAsNumber,
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                },
                minLength: {
                  value: minLength,
                  message: `Mínimo ${minLength} caracteres`,
                },
                maxLength: {
                  value: maxLength,
                  message: `Máximo ${maxLength} caracteres`,
                },

                pattern: {
                  value: validation,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      {warning ? warning : <span> no es válido.</span>}
                    </>
                  ),
                },
              })}
            />
            {toggleIcon}
          </div>
        );

      case "textarea":
        return (
          <div>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <textarea
              id={id}
              name={name}
              value={value}
              rows={rows}
              placeholder={placeholder}
              className={`${styleInput}
                ${errors[id] ? "border-red-500 border-2" : "border-gray-300"} `}
              {...register(id, {
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                  maxLength: {
                    value: maxLength,
                    message: `Máximo ${maxLength} caracteres`,
                  },
                },

                pattern: {
                  value: validation,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      {warning ? warning : <span> no es válido.</span>}
                    </>
                  ),
                },
              })}
            />
          </div>
        );

      case "select":
        return (
          <>
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
            <select
              id={id}
              value={value}
              className={`${styleSelect}
              ${errors[id] ? "border-red-500 border-2" : "border-gray-300"} `}
              {...register(id, {
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                },
                validate: (value: any) => value !== "",
              })}
            >
              {options &&
                options.map((option: any) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </>
        );

      case "checkbox":
        return (
          <div className="flex gap-2 items-center">
            <input
              id={id}
              type={type}
              value={value}
              label={label}
              className={styleCheckbox}
              {...register(id, {
                required: {
                  value: required,
                  message: errorCheckbox,
                },
              })}
            />
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {toLink ? (
                <Link to={toLink} target="_blank">
                  <div className="underline hover:text-[#00A099] cursor-pointer">
                    {label}
                  </div>
                </Link>
              ) : (
                label
              )}
            </label>
          </div>
        );

      case "radio":
        return (
          <div className="flex gap-2 items-center">
            <input
              id={id}
              type={type}
              value={value}
              label={label}
              className={styleCheckbox}
              {...register(id, {
                required: {
                  value: required,
                  message: (
                    <>
                      <span className="capitalize">{name}</span>
                      <span> es requerido.</span>
                    </>
                  ),
                },
              })}
            />
            <label
              className={`${label ? "visible" : "invisible"} ${
                errors[id] ? "text-red-500 dark:text-red-500" : "text-black"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
          </div>
        );
    }
  }

  return (
    <div className="pt-2">
      {renderField()}
      {errors[id] && (
        <p className="text-red-500 text-sm mt-2">{errors[id].message}</p>
      )}
    </div>
  );
};

export default FormField;
