import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { FaSistrix } from "react-icons/fa";

function Table({ columns, data, title }) {
  // Configurar la tabla con las características necesarias
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: "buyDate", desc: true }],
      },
    },
    useGlobalFilter, // Filtro global
    useSortBy, // Ordenamiento
    usePagination // Paginación
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    pageSize,
    setPageSize,
    page,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
  } = tableInstance;

  const pageSizes = [10, 20, 50, 100, 300, 500];
  const [selectedPageSize, setSelectedPageSize] = React.useState(pageSize);

  // Cambiar el tamaño de página seleccionado
  const changePageSize = (newSize) => {
    setSelectedPageSize(newSize);
    setPageSize(newSize);
    gotoPage(0);
  };

  return (
    <div className="lg:px-4 sm:px-2 px-4">
      <div className="w-full text-center">
        <h1 className="font-bold text-4xl text-black pb-6">{title}</h1>
      </div>
      <div className="flex flex-row mb-1 sm:mb-0 justify-between w-full">
        <p>Número de Registros: {preGlobalFilteredRows.length}</p>
        <div className="text-end">
          <div className="flex flex-col md:flex-row w-3/4 md:w-full max-w-sm md:space-x-3 space-y-3 md:space-y-0 justify-center">
            <div className="relative">
              <input
                type="search"
                value={state.globalFilter || ""}
                placeholder="Buscar Paciente"
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                className="bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-cdgreen focus:border-transparent"
              />
              <FaSistrix className="absolute right-0 top-0 mt-2 mr-4 text-2xl" />
            </div>
          </div>
          <div className="items-center mr-4">
            <label className="mr-2">Mostrar:</label>
            <select
              value={selectedPageSize}
              onChange={(e) => changePageSize(Number(e.target.value))}
            >
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        {data.length !== 0 ? (
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="px-5 py-3 font-semibold bg-white border-b text-sm uppercase"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          className="px-8 py-5 border-b border-gray-200 bg-white text-sm text-center"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="flex justify-center content-center">
            <h1>cargandoooooo</h1>
          </div>
        )}

        <ul className="inline-flex -space-x-px mt-4 w-full justify-center">
          <li>
            <a className="bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 ml-0 rounded-l-lg leading-tight py-2 px-3">
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                className={`pagination-button ${
                  !canPreviousPage && "disabled"
                }`}
              >
                Anterior
              </button>
            </a>
          </li>
          <li>
            <a className="bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3">
              <strong>{state.pageIndex + 1}</strong>{" "}
            </a>
          </li>
          <li>
            <a className="bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3">
              <strong>de</strong>{" "}
            </a>
          </li>
          <li>
            <a className="bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 leading-tight py-2 px-3">
              <strong>{pageCount || 1}</strong>{" "}
            </a>
          </li>
          <li>
            <a className="bg-white border border-gray-300 text-gray-500 hover:bg-gray-100 hover:text-gray-700 rounded-r-lg leading-tight py-2 px-3">
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className={`pagination-button ${!canNextPage && "disabled"}`}
              >
                Siguiente
              </button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Table;
