import { useEffect, useState } from "react";
import { getPatientsId } from "../../../services/getPatients";
import { getPaypalPayments } from "../../../services/paypalPayments";
import PDFRecordPR from "../../../components/PDFGenerator/PDFRecordPR";
import LoadingComponent from "../../../components/LoadingComponent";
import referenceNames from "../../../components/data/referenceNames";
import rolNames from "../../../components/data/rolName";
import referenceStatus from "../../../components/data/referenceStatus";
import PDFVoucherPR from "../../../components/PDFGenerator/PDFVoucherPR";
import PDFrM_PR from "../../../components/PDFGenerator/PDFmedicalRecomPR";
import { getImg } from "../../../application/saveImg";
import Modal from "../../../components/Modal";
import { useAuthContext } from "../../../contexts/authContext";
import FormField from "../../../components/FormFiel";
import { useForm } from "react-hook-form";
import DropFile from "../../../components/DropFile";
import { getMedicalRecordPr } from "../../../services/getMedicalRecordPr";
import { updateStatusPayment } from "../../../services/putTransactio";
import { getPDFUrls, savePDFs } from "../../../application/savePdf";
import AlertsMenssages from "../../../components/AlertsMenssages";
import DownloadPDF from "../../../components/PDFGenerator/DownloadPDF";
import referenceDispensaryLocation from "../../../components/data/referenceDispensaryLocation";

interface Patient {
  firstName: string;
  lastName: string;
  dni: string;
  email: string;
  phone: string;
  country: string;
  medicalRecordComplete: boolean;
  reference: number;
  rol: number;
  id: number;
  status: number;
}

function PatientsView() {
  const { user } = useAuthContext();
  const [idPatient, setIdPatient] = useState<number | null>(null);
  const [socialSecurity, setSocialSecurity] = useState<string | null>(null);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [payments, setPayments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [img, setImg] = useState<any[]>([]);
  const [paymentId, setPaymentId] = useState<number | null>(null);
  const [isSaved, setIsSaved] = useState(false);

  const [pdfsSaved, setPdfsSaved] = useState(false);
  const [voucherPdf, setVoucherPdf] = useState<File | null>(null);
  const [recomendationPdf, setRecomendationPdf] = useState<File | null>(null);

  const [getVoucherPdf, setGetVoucherPdf] = useState<string>("");
  const [getRecomendationPdf, setGetRecomendationPdf] = useState<string>("");

  // Agregar un estado para controlar si se debe llamar a getPDFUrls
  const [shouldFetchPDFs, setShouldFetchPDFs] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const numericId = id ? parseInt(id, 10) : null;
    setIdPatient(numericId);

    if (id) {
      getPatientsId(id).then((data: Patient) => {
        setPatient(data);
        setIsLoading(false);
      });
    }

    getPaypalPayments(id).then((data: any) => {
      setPayments(data);
      setIsLoading(false);
    });

    getMedicalRecordPr(id).then((data: any) => {
      if (data) {
        setSocialSecurity(data.socialSecurity);
        getImg(data.socialSecurity).then((data: any) => {
          setImg(data);
          setIsLoading(false);

          // Configurar shouldFetchPDFs como true cuando tengas el valor correcto de socialSecurity
          setShouldFetchPDFs(true);
        });
      }
    });
  }, []);

  // Use un useEffect adicional para llamar a getPDFUrls cuando shouldFetchPDFs sea true
  useEffect(() => {
    if (shouldFetchPDFs) {
      getPDFUrls(socialSecurity).then((pdfUrls: any) => {
        if (pdfUrls) {
          setGetVoucherPdf(pdfUrls[0]);
          setGetRecomendationPdf(pdfUrls[1]);
        }
      });
    }
  }, [shouldFetchPDFs]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const stausSelect = watch("status");

  const onSubmit = async (data: any) => {
    try {
      if (data.status == "5") {
        savePDFs(
          socialSecurity,
          voucherPdf,
          recomendationPdf,
          handlePDFSaveSuccess
        );
      }

      await updateStatusPayment(paymentId, data.status);
      setIsSaved(true);

      // Copia de la lista de payments
      const updatedPayments = [...payments];
      // Encuentra el índice del pago que se actualizó
      const paymentIndex = updatedPayments.findIndex(
        (payment) => payment.id === paymentId
      );
      // Actualiza el estado del pago en la copia
      if (paymentIndex !== -1) {
        updatedPayments[paymentIndex].status = data.status;
      }
      // Actualiza el estado local con la copia actualizada
      setPayments(updatedPayments);
      setIsSaved(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePDFSaveSuccess = () => {
    setPdfsSaved(true);
  };

  return (
    <div className="h-full">
      <div className="container mx-auto">
        <div className="text-center p-8">
          {isLoading && <LoadingComponent />}

          {!isLoading && patient && payments && (
            <>
              <h1 className="font-bold text-4xl text-black pb-4 capitalize">
                {patient.firstName} {patient.lastName}
              </h1>
              <div className="container max-w-screen-lg mx-auto text-start">
                <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                    <div className="text-gray-600 text-center">
                      <p className="font-medium text-lg">Datos Personales</p>
                      <div className="mx-auto w-52 h-52 rounded-full bg-gray-200 flex items-center justify-center">
                        {img[1] ? (
                          <img
                            src={img[1]}
                            className="w-48 h-48 rounded-full object-cover object-center"
                            alt="Imagen"
                          />
                        ) : (
                          <div className="w-full flex flex-col justify-center items-center">
                            <svg
                              role="status"
                              className="inline h-14 w-14 animate-spin mr-2 text-gray-400 fill-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <h3 className="mt-4 text-gray-500 text-lg">
                              Cargando Imagen
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="lg:col-span-2">
                      <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                        <div className="md:col-span-2">
                          <label>Nombre completo</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                            {patient.firstName} {patient.lastName}
                          </p>
                        </div>
                        <div className="md:col-span-2">
                          <label>Referencia</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                            {referenceNames[patient.reference] || "No definido"}
                          </p>
                        </div>

                        <div className="md:col-span-1">
                          <label>Rol</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                            {rolNames[patient.rol] || "No definido"}
                          </p>
                        </div>

                        <div className="md:col-span-3">
                          <label>Email</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center">
                            {patient.email}
                          </p>
                        </div>

                        <div className="md:col-span-2">
                          <label>Telefono</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                            {patient.phone}
                          </p>
                        </div>

                        <div className="md:col-span-2">
                          <label>Pais</label>
                          <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                            {patient.country === "PR"
                              ? "Puerto Rico"
                              : "Argentina"}
                          </p>
                        </div>
                        {patient.country === "PR" && (
                          <div className="md:col-span-2">
                            <label>Seguro Social</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {socialSecurity}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {patient.medicalRecordComplete && user.rol === 4 && (
                      <PDFRecordPR id={idPatient} />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {!isLoading && img && (
            <div className="container max-w-screen-lg mx-auto text-start">
              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                  <div className="text-gray-600">
                    <p className="font-medium text-lg">Fotos</p>
                  </div>

                  <div className="lg:col-span-2">
                    <div className="flex flex-col justify-between items-center sm:flex-row gap-4">
                      {img[0] && (
                        <Modal
                          title="Foto del voucher"
                          contents={
                            <div className="flex flex-col justify-center">
                              <h1 className="font-bold text-3xl text-black pb-4 capitalize text-center">
                                Foto del voucher
                              </h1>
                              <img src={img[0]} />
                            </div>
                          }
                        />
                      )}

                      <Modal
                        title="Foto de Perfil"
                        contents={
                          <div className="flex flex-col justify-center">
                            <h1 className="font-bold text-3xl text-black pb-4 capitalize text-center">
                              Foto de Perfil
                            </h1>
                            <img src={img[1]} />
                          </div>
                        }
                      />
                      <Modal
                        title="Foto de Real ID"
                        contents={
                          <div className="flex flex-col justify-center">
                            <h1 className="font-bold text-3xl text-black pb-4 capitalize text-center">
                              Foto del Real ID
                            </h1>
                            <img src={img[2]} />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isLoading && payments && (
            <>
              {payments.map((payment) => (
                <div
                  key={payment.id}
                  className="container max-w-screen-lg mx-auto text-start"
                >
                  <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                    <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                      <div className="text-gray-600">
                        <p className="font-medium text-lg">Detalles del Pago</p>
                      </div>

                      <div className="lg:col-span-2">
                        <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                          <div className="md:col-span-2">
                            <label>Metodo de pago</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {payment.method}
                            </p>
                          </div>
                          <div className="md:col-span-2">
                            <label>Estado del pago</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {payment.state}
                            </p>
                          </div>
                          {user.rol === 4 && (
                            <div className="md:col-span-1">
                              <label>Monto</label>
                              <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                                {payment.amount}
                              </p>
                            </div>
                          )}

                          <div className="md:col-span-3">
                            <label>Order Id</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center">
                              {payment.orderId}
                            </p>
                          </div>

                          <div className="md:col-span-2">
                            <label>Motivo del pago</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {payment.type}
                            </p>
                          </div>

                          <div className="md:col-span-3">
                            <label>Lugar de retiro</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {referenceDispensaryLocation[
                                payment.dispensaryLocation
                              ] || "No definido"}
                            </p>
                          </div>

                          <div className="md:col-span-2">
                            <label>Número de tramite</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {payment.id}
                            </p>
                          </div>

                          <div className="md:col-span-3">
                            <label>Fecha de pago</label>
                            <p className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 flex items-center capitalize">
                              {payment.date}
                            </p>
                          </div>

                          <div className="md:col-span-5 bg-cdgreen h-14 flex items-center mt-2">
                            <h1 className="ml-2 font-semibold text-xl text-white">
                              Estado actual del tramite:{" "}
                              {referenceStatus[payment.status] || "No definido"}
                            </h1>
                          </div>
                          {user.rol === 4 && (
                            <div className="md:col-span-5">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <FormField
                                  label="Cambiar estado del tramite"
                                  id="status"
                                  name="nuevo estado del tramite"
                                  required={true}
                                  type="select"
                                  errors={errors}
                                  register={register}
                                  options={[
                                    {
                                      value: "",
                                      label: "Seleccionar estado",
                                    },
                                    {
                                      value: "1",
                                      label: "Pendiente de cita medica",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Pendiente de recomendación médica",
                                    },
                                    {
                                      value: "3",
                                      label: "Recomendación médica finalizada",
                                    },
                                    {
                                      value: "4",
                                      label: "Pendiente en Depto. de salud",
                                    },
                                    { value: "5", label: "Aprobado" },
                                    { value: "6", label: "En Revisión" },
                                    { value: "7", label: "Entregado" },
                                  ]}
                                />

                                {stausSelect == 5 && (
                                  <div className="flex w-full gap-1 mt-4">
                                    <DropFile
                                      name="VOUCHER"
                                      inputId="voucher"
                                      onFileChange={(file) => {
                                        setVoucherPdf(file);
                                      }}
                                    />

                                    <DropFile
                                      name="RECOMENDACION"
                                      inputId="recomendacion"
                                      onFileChange={(file) => {
                                        setRecomendationPdf(file);
                                      }}
                                    />
                                  </div>
                                )}

                                {payment.status != stausSelect &&
                                  stausSelect != "" &&
                                  !isSaved && (
                                    <div className="text-end mt-4">
                                      <button
                                        onClick={() => {
                                          setPaymentId(payment.id);
                                        }}
                                        className="w-full flex justify-center bg-cdgreen  hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
                                      >
                                        Guardar
                                      </button>
                                    </div>
                                  )}
                              </form>
                              {pdfsSaved && (
                                <div className="mt-2">
                                  <AlertsMenssages
                                    type="success"
                                    message="PDFs guardados exitosamente"
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          {user.rol === 3 && payment.status === 5 && (
                            <div className="md:col-span-5">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <FormField
                                  label="Cambiar estado del tramite"
                                  id="status"
                                  name="nuevo estado del tramite"
                                  required={true}
                                  type="select"
                                  errors={errors}
                                  register={register}
                                  options={[
                                    {
                                      value: "",
                                      label: "Seleccionar estado",
                                    },
                                    {
                                      value: "7",
                                      label: "Entregado",
                                    },
                                  ]}
                                />

                                {payment.status != stausSelect &&
                                  stausSelect != "" &&
                                  !isSaved && (
                                    <div className="text-end mt-4">
                                      <button
                                        onClick={() => {
                                          setPaymentId(payment.id);
                                        }}
                                        className="w-full flex justify-center bg-cdgreen  hover:bg-[#8CBB93] text-gray-100 p-3 rounded-lg tracking-wide font-semibold  cursor-pointer transition ease-in duration-500"
                                      >
                                        Guardar
                                      </button>
                                    </div>
                                  )}
                              </form>
                            </div>
                          )}

                          {payments.map((payment) => (
                            <div className="md:col-span-5 flex items-center justify-center mt-4">
                              {((user.rol === 4 && payment.status === 5) ||
                                (user.rol === 4 && payment.status === 7)) && (
                                <>
                                  <div className="gap-2 flex flex-col w-max lg:flex-row">
                                    <div>
                                      <DownloadPDF
                                        pdfUrl={getVoucherPdf}
                                        buttonText="Descargar Voucher"
                                      />
                                    </div>
                                    <div>
                                      <DownloadPDF
                                        pdfUrl={getRecomendationPdf}
                                        buttonText="Descargar Recomendacin"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}

                          {payments.map((payment) => (
                            <div className="md:col-span-5 flex items-center justify-center mt-4">
                              {user.rol === 3 && payment.status === 5 && (
                                <>
                                  <div className="gap-2 flex flex-col w-max lg:flex-row">
                                    <div>
                                      <DownloadPDF
                                        pdfUrl={getVoucherPdf}
                                        buttonText="Descargar Voucher"
                                      />
                                    </div>
                                    <div>
                                      <DownloadPDF
                                        pdfUrl={getRecomendationPdf}
                                        buttonText="Descargar Recomendacin"
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientsView;
