const baseURL = "https://canadoctors.herokuapp.com/special";

const getSpecialPatients = async (employeeId) => {
  const response = await fetch(`${baseURL}/${employeeId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
  });

  try {
    const data = await response.json();
    return data;
  } catch (err) {
    //console.log(err.message);
  }
};

export { getSpecialPatients };
