import { useEffect, useState } from "react";
import { useAuthContext } from "../../../contexts/authContext";
import { getMedicalRecordAr } from "../../../services/getMedicalRecordAr";
import { getMedicalRecordPr } from "../../../services/getMedicalRecordPr";

import logoCanadoctors from "../../../assets/images/logos/canadoctors/canadoctorsColors.svg";
import logoPlanteo from "../../../assets/images/logos/elPlanteo/elPlanteoColors.svg";

type medicalRecordAr = {
  address: string;
  city: string;
  birthDate: any;
  idType: string;
  idNumber: number;
  reprocannCode: string;
  socialWork: string;
  gender: string;
};

type medicalRecordPr = {
  address: string;
  birthDate: any;
  gender: string;
  socialSecurity: number;
  zipCode: number;
  eyesColor: string;
  profession: string;
  foots: number;
  inches: number;
};

function UserCharacteristics() {
  const { user } = useAuthContext();
  const [medicalRecordAr, setMedicalRecordAr] = useState<medicalRecordAr>();
  const [medicalRecordPr, setMedicalRecordPr] = useState<medicalRecordPr>();

  useEffect(() => {
    if (user.medicalRecordComplete === true && user.country === "AR") {
      const recordAr = async () => {
        await getMedicalRecordAr(user.id).then((res) => {
          setMedicalRecordAr(res);
        });
      };
      recordAr();
    } else if (user.medicalRecordComplete === true && user.country === "PR") {
      const recordPr = async () => {
        await getMedicalRecordPr(user.id).then((res) => {
          setMedicalRecordPr(res);
        });
      };
      recordPr();
    }
  }, [user.id, user.medicalRecordComplete, user.country]);

  return (
    <div className="w-full">
      <div className="h-80 mx-auto shadow-xl rounded-xl bg-white mb-6">
        <div className="flex justify-center sm:justify-start font-medium px-4 pt-2">
          {user.medicalRecordComplete === true ? (
            <span className="text-xl">Datos Personales</span>
          ) : (
            <span className="text-xl">Completar Perfil</span>
          )}
        </div>
        <hr className="mt-2" />

        {user.country === "AR" && user.medicalRecordComplete === true && (
          <div className="m-4">
            <div className="flex font-bold text-sm p-1">
              Ciudad:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordAr?.city}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Dirección:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordAr?.address}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Fecha de nacimiento:
              <span className="flex items-center font-light text-sm ml-1">
                {medicalRecordAr?.birthDate
                  .split(" ")[0]
                  .split("-")
                  .reverse()
                  .join("-")}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Tipo de documento:
              <span className="flex items-center font-light text-sm ml-1 uppercase">
                {medicalRecordAr?.idType}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Número de documento:
              <span className="flex items-center font-light text-sm ml-1">
                {medicalRecordAr?.idNumber}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Código Reprocann:
              <span className="flex items-center font-light text-sm ml-1 uppercase">
                {medicalRecordAr?.reprocannCode}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Obra social:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordAr?.socialWork}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Género:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordAr?.gender === "M" && "Masculino"}
                {medicalRecordAr?.gender === "F" && "Femenino"}
              </span>
            </div>
          </div>
        )}

        {user.country === "PR" && user.medicalRecordComplete === true && (
          <div className="m-4">
            <div className="flex font-bold text-sm p-1">
              Dirección:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordPr?.address}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Fecha de nacimiento:
              <span className="flex items-center font-light text-sm ml-1">
                {medicalRecordPr?.birthDate
                  .split(" ")[0]
                  .split("-")
                  .reverse()
                  .join("-")}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Color de ojos:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordPr?.eyesColor}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Estatura:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordPr?.foots}.{medicalRecordPr?.inches}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Profesión:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordPr?.profession}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Seguro social:
              <span className="flex items-center font-light text-sm ml-1">
                {medicalRecordPr?.socialSecurity}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Código postal:
              <span className="flex items-center font-light text-sm ml-1">
                {medicalRecordPr?.zipCode}
              </span>
            </div>
            <div className="flex font-bold text-sm p-1">
              Género:
              <span className="flex items-center font-light text-sm ml-1 capitalize">
                {medicalRecordPr?.gender === "M" && "Masculino"}
                {medicalRecordPr?.gender === "F" && "Femenino"}
              </span>
            </div>
          </div>
        )}

        {user.medicalRecordComplete === false && (
          <div className="flex justify-center h-64 p-4">
            {user.reference !== 3 ? (
              <img src={logoCanadoctors} alt="logo" width={400} />
            ) : (
              <img
                src={logoPlanteo}
                alt="logo"
                width={400}
                className="object-contain"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserCharacteristics;
