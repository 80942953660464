import React, { useState, ChangeEvent } from "react";
import { FaUpload, FaTrash } from "react-icons/fa";
import AlertsMenssages from "../AlertsMenssages";

interface DropFileProps {
  onFileChange: (file: File | null) => void;
  inputId: string;
  name: string;
}

function DropFile({ onFileChange, inputId, name }: DropFileProps) {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      onFileChange(selectedFile);
    }
  };

  const handleFileDelete = () => {
    setFile(null);
    onFileChange(null);
  };

  return (
    <main className="flex items-center justify-center font-sans">
      <label
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          const droppedFile = e.dataTransfer?.files[0];
          if (droppedFile) {
            setFile(droppedFile);
            onFileChange(droppedFile);
          }
        }}
        htmlFor={inputId}
        className="mx-auto cursor-pointer flex max-w-lg h-42 flex-col items-center justify-center rounded-xl border-2 border-dashed hover:opacity-80 border-cdgreen bg-white text-center"
      >
        {file ? (
          <div className="group w-full rounded-lg relative object-fill overflow-hidden h-52 flex flex-col justify-center item-center">
            <p className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
              Archivo seleccionado:
            </p>
            <p className="mt-2 text-gray-500 tracking-wide">{file.name}</p>
            <div className="hidden group-hover:flex justify-center items-center absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
              <FaTrash
                className="text-white text-6xl"
                onClick={handleFileDelete}
              />
            </div>
          </div>
        ) : (
          <div className="flex w-full flex-col items-center p-6">
            <FaUpload className="text-4xl text-cdgreen" />
            <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">
              Cargar {name} PDF
            </h2>
            <p className="mt-2 text-gray-500 tracking-wide">
              Cargar o arrastrar su archivo PDF aquí
            </p>
          </div>
        )}

        <input
          id={inputId}
          type="file"
          className="hidden"
          accept=".pdf"
          onChange={handleFileChange}
        />
      </label>
    </main>
  );
}

export default DropFile;
