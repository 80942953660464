import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import Table from "../../../components/Table";
import { useAuthContext } from "../../../contexts/authContext";
import LoadingComponent from "../../../components/LoadingComponent";
import {
  getTransactions,
  getTransactionsReference,
} from "../../../services/getTransactions";
import referenceStatus from "../../../components/data/referenceStatus";
import TableExcel from "../../../components/TableExcel";

interface Patient {
  firstName: string;
  lastName: string;
  email: string;
  phone: number;
  id: string;
  medicalRecordComplete: boolean;
  transactions: Transaction[];
}

interface Transaction {
  id: string;
  amount: number;
  date: string;
  type: string;
}

function PatientsManagementTable() {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [allPatients, setAllPatients] = useState<Patient[]>([]);

  useEffect(() => {
    setIsLoading(true);

    if (user.rol === 3) {
      getTransactionsReference(user.reference).then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }

    if (user.rol === 4) {
      getTransactions().then((data: Patient[]) => {
        setAllPatients(data);
        setIsLoading(false);
      });
    }
  }, [user.rol]);

  const columns = useMemo(
    () => [
      {
        Header: "id transacción",
        accessor: "id",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Fecha",
        accessor: "date",
        Cell: ({ value }: { value: string }) => {
          // Convertir la fecha a formato "dd-mm-aaaa"
          const fecha =
            value.substring(8, 10) +
            "/" +
            value.substring(5, 7) +
            "/" +
            value.substring(0, 4);

          return <span className="capitalize">{fecha}</span>;
        },
      },
      {
        Header: "Nombre",
        accessor: "name",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Apellido",
        accessor: "lastName",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: { value: string }) => <span>{value}</span>,
      },
      {
        Header: "Metodo",
        accessor: "method",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Tipo Licencia",
        accessor: "type",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: "Estado",
        accessor: "status",
        Cell: ({ value }: { value: string }) => (
          <span className="capitalize">{referenceStatus[parseInt(value)]}</span>
        ),
      },
      {
        Header: "Ver +",
        accessor: "patientId",
        Cell: ({ value }: { value: string }) => (
          <div>
            <Link to={`ver/?id=${value}`}>
              <FaEye className="text-xl text-gray-700 hover:opacity-75" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      {user.rol === 4 && (
        <TableExcel
          data={allPatients}
          filename="Tabla de gestion"
          sheetName="transacciones"
        />
      )}

      <Table
        columns={columns}
        data={allPatients}
        title={"Gestion de pacientes"}
      />
    </>
  );
}

export default PatientsManagementTable;
