import React, { useEffect } from "react";
import { useMercadopago } from "react-sdk-mercadopago";
import "./styleButtom.css";

export default function CheckoutAr() {
  const mercadopago = useMercadopago.v2(
    "APP_USR-35405e8f-a164-4644-8957-173cc026ea7f",
    {
      locale: "en-US",
    }
  );

  useEffect(() => {
    if (mercadopago) {
      mercadopago.checkout({
        preference: {
          id: "72394236-d105f249-3144-47f2-becc-a1ca5d69690c",
        },
        render: {
          container: ".cho-container",
          label: "Pagar",
        },
        theme: { headerColor: "#00A099", elementsColor: "#00A099" },
      });
    }
  }, [mercadopago]);

  return (
    <div className="mt-4 relative flex-1 flex-col justify-center">
      <div className="flex flex-col justify-center items-center">
        <div className="bg-gray-100 flex flex-col justify-center items-center text-black shadow-lg shadow-gray-300 w-full h-auto p-4 rounded-2xl relative mx-12">
          <p className="text-black font-semibold capitalize text-lg">
            Video consulta médica
          </p>
          <p className="text-black text-md mt-5">
            Acceso a plataforma Canadoctors, consulta con especialista médico en
            endocannabinología.
          </p>

          <span className="text-lg font-extrabold mt-5">Total: $15.000</span>
        </div>
      </div>
      <div className="mt-8 w-full text-center">
        <button className="cho-container hover:opacity-70" />
      </div>
    </div>
  );
}
