import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Función para guardar PDFs en Firebase Storage
export const savePDFs = async (
  id,
  voucherPDF,
  recomendacionPDF,
  onPDFSaveSuccess
) => {
  const storage = getStorage();

  const cuidadetiRef = ref(storage, "cuidadetiTetra/");
  const userFolderRef = ref(cuidadetiRef, id + "/");

  // Nombres de los archivos PDF
  const pdfNames = ["voucher.pdf", "recomendacion.pdf"];
  const pdfs = [voucherPDF, recomendacionPDF];

  for (let i = 0; i < pdfNames.length; i++) {
    const pdfName = pdfNames[i];
    const pdfFile = pdfs[i] || null;

    if (pdfFile !== null) {
      const pdfStorageRef = ref(userFolderRef, pdfName);
      await uploadBytes(pdfStorageRef, pdfFile);
    }
  }

  // Llama a la función de éxito cuando los PDFs se han guardado con éxito
  if (onPDFSaveSuccess) {
    onPDFSaveSuccess();
  }
};

// Función para obtener las URL de descarga de los PDFs guardados
export const getPDFUrls = async (id) => {
  const storage = getStorage();
  const cuidadetiRef = ref(storage, "cuidadetiTetra/");
  const userFolderRef = ref(cuidadetiRef, id + "/");

  const voucherRef = ref(userFolderRef, "voucher.pdf");
  const recomendacionRef = ref(userFolderRef, "recomendacion.pdf");

  const downloadPromises = [];

  // Verificar si el elemento 'voucher' existe y obtener su URL si existe
  if (await exists(voucherRef)) {
    downloadPromises.push(getDownloadURL(voucherRef));
  } else {
    downloadPromises.push(null); // Agregar null en caso de que no exista 'voucher'
  }

  // Verificar si el elemento 'recomendacion' existe y obtener su URL si existe
  if (await exists(recomendacionRef)) {
    downloadPromises.push(getDownloadURL(recomendacionRef));
  } else {
    downloadPromises.push(null); // Agregar null en caso de que no exista 'recomendacion'
  }

  // Esperar a que todas las promesas de descarga se completen
  const pdfUrls = await Promise.all(downloadPromises);

  return pdfUrls;
};

// Función auxiliar para verificar si una referencia existe
const exists = async (ref) => {
  try {
    await getDownloadURL(ref);
    return true; // La referencia existe
  } catch (error) {
    if (error.code === "storage/object-not-found") {
      return false; // La referencia no existe
    } else {
      throw error; // Ocurrió un error diferente
    }
  }
};
