const baseURL = "https://canadoctors.herokuapp.com/users";

const getUsersEmails = async (email) => {
  const response = await fetch(baseURL, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    },
  });
  const data = await response.json();
  //comparar si el correo existe en la base de datos
  const user = data.find((user) => user.email === email);
  return user;
};

export { getUsersEmails };
