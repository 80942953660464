import {
  FaHome,
  FaClinicMedical,
  FaUser,
  FaMoneyCheckAlt,
  FaUserMd,
  FaStethoscope,
  FaUserAlt,
} from "react-icons/fa";

const routesArray = [
  {
    role: 1,
    routes: [
      {
        label: "Home",
        subroutes: [
          {
            to: "/paciente",
            icon: FaHome,
            title: "Inicio",
          },
          {
            to: "/paciente/servicios",
            icon: FaClinicMedical,
            title: "Servicios",
          },
        ],
      },
      {
        label: "Perfil",
        subroutes: [
          {
            to: "/paciente/perfil",
            icon: FaUser,
            title: "Mi Perfil",
          },
          {
            to: "/paciente/misPagos",
            icon: FaMoneyCheckAlt,
            title: "Mis Pagos",
          },
        ],
      },
    ],
  },
  {
    role: 2,
    routes: [
      {
        label: "Doctores",
        subroutes: [
          {
            to: "/doctores",
            icon: FaUserMd,
            title: "Doctores",
          },
          {
            to: "/doctores/especialidades",
            icon: FaStethoscope,
            title: "Especialidades",
          },
        ],
      },
    ],
  },
  {
    role: 3,
    routes: [
      {
        label: "Dispensario",
        subroutes: [
          {
            to: "/Dispensario",
            icon: FaHome,
            title: "Inicio",
          },
          {
            to: "pacientes",
            icon: FaUserAlt,
            title: "Pacientes",
          },
        ],
      },
    ],
  },
  {
    role: 4,
    routes: [
      {
        label: "Administrador",
        subroutes: [
          {
            to: "/administrador",
            icon: FaHome,
            title: "Inicio",
          },
          {
            to: "pacientes",

            icon: FaUserAlt,
            title: "Pacientes",
          },
        ],
      },
    ],
  },
];

export default routesArray;
