import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
} from "react";
import Loading from "../container/Loading";

export const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [state, setState] = useState({ loadingLogin: false, error: false });
  const [userId, setUserId] = useState(
    JSON.parse(window.localStorage.getItem("user")) ?? false
  );
  const [isAuthenticated, setisAuthenticated] = useState(
    window.localStorage.getItem("token") ?? false
  );

  const login = useCallback(function (data) {
    setState({ loadingLogin: true, error: false });
    fetch("https://canadoctors.herokuapp.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setState({ loadingLogin: false, error: false });
        const token = data.token;
        window.localStorage.setItem("token", token, true);
        window.localStorage.setItem("user", JSON.stringify(data.id), true);
        setisAuthenticated(token, true);
        setUserId(data.id);
      })
      .catch((err) => {
        setState({ loadingLogin: false, error: true });
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      fetch(`https://canadoctors.herokuapp.com/users/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUser(data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [userId]);

  const logout = useCallback(function () {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
    setisAuthenticated(false);
    setLoading(false);
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuthenticated,
      user,
      loading,
      isLoginLoading: state.loadingLogin,
      hasLoginError: state.error,
    }),
    [
      login,
      logout,
      isAuthenticated,
      user,
      loading,
      state.error,
      state.loadingLogin,
    ]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <Loading />}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  return useContext(AuthContext);
};
